import React from "react";
import { Route, Routes } from "react-router-dom-v5-compat";
import { RoleRoute } from "../../ProtectedRoutes";
import { SecondaryNavLayout, SecondaryNavLayoutProps } from "../../SecondaryNav/SecondaryNavLayout";
import { EducationResourcesPage } from "./EducationResourcesPage";
import { NAV_ORG_EDUCATION_RESOURCES } from "./EducationResourcesPaths";

const secondaryNavLayoutProps: SecondaryNavLayoutProps = {
  current: {
    to: NAV_ORG_EDUCATION_RESOURCES,
    name: "Resources",
  },
  enableAthleteSearch: true,
  tabs: [
    {
      name: "Resources",
      to: "/",
    },
  ],
};

export const EducationResourcesRouter = () => (
  <Routes>
    <Route element={<SecondaryNavLayout {...secondaryNavLayoutProps} />}>
      <Route element={<RoleRoute roles={["dietitian", "nonRdAdmin"]} />}>
        <Route index element={<EducationResourcesPage />} />
      </Route>
    </Route>
  </Routes>
);
