import { teamworksProfileHasRequiredFields } from "@notemeal/profile-sync";
import React, { useState } from "react";
import { TeamworksProfileFragment } from "../../../../../../../types";
import { filterProfileForSearchTerm } from "../../utils";
import ProfilesList from "./ProfilesList";

interface TeamworksProfilesListProps {
  selectedId: string | null;
  profiles: readonly TeamworksProfileFragment[];
  onLink: (profile: TeamworksProfileFragment) => void;
  onAdd: (profile: TeamworksProfileFragment) => void;
}

const TeamworksProfilesList = (props: TeamworksProfilesListProps) => {
  const { selectedId, profiles } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const searchProps = { searchTerm, setSearchTerm };
  const filteredProfiles = profiles.filter(p => {
    return filterProfileForSearchTerm({
      firstName: p.firstName || "",
      lastName: p.lastName || "",
      searchTerm,
    });
  });

  return (
    <ProfilesList
      {...props}
      hasAdditionalProfileInfo
      profiles={filteredProfiles}
      searchProps={searchProps}
      title={`Unlinked Teamworks Profiles`}
      columnMode={selectedId ? "Link" : "Add"}
      getProfileForDisplay={profile => {
        const firstMembership = profile.memberships && profile.memberships.length > 1 && profile.memberships[0];
        return {
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: profile.email,
          phoneNumber: profile.cellPhone,
          missingTeamworksRequiredFields: !teamworksProfileHasRequiredFields(profile),
          additionalTeamworksData: {
            team: firstMembership ? firstMembership.teamName : null,
            usertype: firstMembership && firstMembership.userTypes ? firstMembership.userTypes.map(ut => ut.userTypeName).join(", ") : null,
            athleteStatus: firstMembership ? firstMembership.athleteStatus?.name : null,
          },
          rolesString: null,
        };
      }}
    />
  );
};

export default TeamworksProfilesList;
