import { Box, Button, Divider, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { SectionHeader } from "../../componentLibrary/SectionHeader";
import { ResourceUploader } from "../../components/EducationResource/ResourceUploader";
import LabeledSelect from "../../components/universal/LabeledSelect";
import { EducationResourceTableItemFragment, TeamPreviewFragment, useEducationResourceCategoriesQuery } from "../../types";
import AdvancedSelectionDialog from "../Tags/Dialogs/Mutation/AdvancedSelection/AdvancedSelectionDialog";
import ShareWithInfo from "../Tags/Dialogs/Mutation/AdvancedSelection/ShareWithInfo";
import TeamSelector from "../Tags/Selectors/TeamSelector";
import { buildInitialEditAdvancedSelectionState } from "../Tags/reducers/advancedSelectionReducers";
import { EducationResourceFormType } from "./EducationResourceSchema";
import { ShareAction, ShareState } from "./types";
import { getInitialState } from "./utils";

interface EducationResourceFormProps {
  form: UseFormReturn<EducationResourceFormType>;
  mode: "create" | "edit" | "duplicate";
  fileName: string;
  setFileName: (name: string) => void;
  shareState: ShareState;
  shareDispatch: React.Dispatch<ShareAction>;
  setFormIsDirty: (formIsDirty: boolean) => void;
  initialShareValuesForEdit?: EducationResourceTableItemFragment;
}

export const EducationResourceForm = ({
  form,
  mode,
  fileName,
  setFileName,
  shareState,
  shareDispatch,
  setFormIsDirty,
  initialShareValuesForEdit,
}: EducationResourceFormProps) => {
  const [advancedSelectOpen, setAdvancedSelectOpen] = useState(false);
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = form;

  const { data } = useEducationResourceCategoriesQuery();

  const categories = data?.educationResourceCategories ? [...data?.educationResourceCategories] : [];

  const onChangeTeams = (selectedTeams: TeamPreviewFragment[]) => {
    shareDispatch({ type: "ChangeTeamsAction", payload: { teams: selectedTeams } });

    setFormIsDirty(true);
  };

  useEffect(() => {
    if (mode === "edit" && initialShareValuesForEdit?.teams) {
      shareDispatch({
        type: "ChangeTeamsAction",
        payload: { teams: initialShareValuesForEdit.teams },
      });
    }
    if (mode === "edit" && initialShareValuesForEdit?.advancedSelection) {
      const advancedSelectionState = buildInitialEditAdvancedSelectionState(initialShareValuesForEdit.advancedSelection);
      shareDispatch({
        type: "ChangeAdvancedSelectionAction",
        payload: { advancedSelectionState: advancedSelectionState },
      });
    }
  }, []);

  const initialState = getInitialState(mode, shareState, initialShareValuesForEdit);

  return (
    <>
      <Box>
        <SectionHeader header="Resource Upload" subcopy="Select the file to share with athletes" />
      </Box>
      <ResourceUploader
        setFileKey={key => {
          setValue("fileKey", key, { shouldDirty: true });
        }}
        setFileName={setFileName}
        fileKey={getValues("fileKey")}
        fileName={fileName}
        error={errors.fileKey?.message}
      />
      <Divider />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Controller
          name={"name"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...field}
              placeholder="Resource Name"
              error={Boolean(errors.name)}
              label="Name"
              helperText={errors.name?.message}
              fullWidth
            />
          )}
        />
        <Controller
          name={"description"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...field}
              placeholder="Add description here"
              error={Boolean(errors.description)}
              label="Description (optional)"
              fullWidth
            />
          )}
        />
        <Controller
          name={"label"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <LabeledSelect
              {...field}
              optionToName={option => {
                return `${option.name}`;
              }}
              placeholder={"Label"}
              selectedOption={field.value ?? null}
              options={categories}
              error={Boolean(errors.label)}
              helperText={errors.label && "Required"}
            />
          )}
        />
      </Box>
      <Divider />
      <Box>
        <SectionHeader header="Share With" subcopy="Select the teams and/or athletes to share the resource with" />
      </Box>
      <Box sx={{ display: "flex", gap: 2 }}>
        {shareState.__typename === "Tags" && (
          <ShareWithInfo
            state={shareState.advancedSelectionState}
            onClear={() => {
              shareDispatch({ type: "ClearAdvancedSelectionAction" });
            }}
            maxChips={4}
            containerSx={{ minWidth: `250px` }}
            showAdvancedSelectionClear={true}
            showWrapper
          />
        )}
        {shareState.__typename === "Teams" && (
          <TeamSelector
            sx={{ minWidth: "500px", maxWidth: "500px" }}
            selectedTeams={shareState.teams}
            onChange={onChangeTeams}
            textInputLabel="Teams"
          />
        )}

        <Button
          sx={{ alignSelf: "flex-end" }}
          variant="outlined"
          onClick={() => setAdvancedSelectOpen(true)}>
          Advanced Selection
        </Button>
      </Box>
      {advancedSelectOpen && (
        <AdvancedSelectionDialog
          title="Share Resource"
          open={advancedSelectOpen}
          onClose={() => setAdvancedSelectOpen(false)}
          onSave={advancedSelectionState => {
            shareDispatch({ type: "ChangeAdvancedSelectionAction", payload: { advancedSelectionState } });
            setFormIsDirty(true);
            setAdvancedSelectOpen(false);
          }}
          initialState={initialState}
        />
      )}
    </>
  );
};
