import { z } from "zod";
import { EducationResourceTableItemFragment, CreateEducationResourceInput, EditEducationResourceInput } from "../../types";
import { ShareState } from "./types";
import { buildAdvancedSelectionInput } from "../Tags/reducers/advancedSelectionReducers";

const EducationCategorySchema = z.object(
  {
    id: z.string({ required_error: "Required" }).min(1, { message: "Required" }),
    name: z.string({ required_error: "Required" }).min(1, { message: "Required" }),
  },
  { required_error: "Required" }
);

export const EducationResourceFormSchema = z.object({
  id: z.string().optional(), // only used for edit
  name: z.string({ required_error: "Required" }).min(1, { message: "Required" }).max(40, { message: "Max 40 characters" }),
  description: z.string(),
  label: EducationCategorySchema,
  fileKey: z.string({ required_error: "Required" }).min(1, { message: "Please upload a file below" }),
  isShared: z.boolean(),
});

export type EducationResourceFormType = z.infer<typeof EducationResourceFormSchema>;

export const educationResourceFormDefaultValues = (educationResource?: EducationResourceTableItemFragment): EducationResourceFormType => {
  if (educationResource) {
    const { name, description, category, fileKey, isShared } = educationResource;
    return {
      name,
      description: description ?? "",
      label: {
        id: category.id,
        name: category.name,
      },
      fileKey,
      isShared,
    };
  }
  return {
    name: "",
    description: "",
    label: { name: "", id: "" },
    fileKey: "",
    isShared: false,
  };
};

export const resourceFormToCreateInput = (
  resource: EducationResourceFormType,
  shareState: ShareState
): CreateEducationResourceInput | EditEducationResourceInput => {
  // Base input for both create and edit
  const baseInput = {
    name: resource.name,
    description: resource.description,
    categoryId: resource.label.id,
    fileKey: resource.fileKey,
    isShared: resource.isShared,
    teamIds: shareState.__typename === "Teams" ? shareState.teams.map(({ id }) => id) : null,
    advancedSelectionInput: shareState.__typename === "Tags" ? buildAdvancedSelectionInput(shareState.advancedSelectionState) : null,
  };

  if (resource.id) {
    return {
      ...baseInput,
      id: resource.id,
    };
  }

  return baseInput;
};
