import { logger } from "@notemeal/server/log";
import axios from "axios";
import { Buffer } from "buffer";
import { fileTypeFromBuffer } from "file-type";
import { uploadResourceToAWS } from "libs/shared/ui/src/lib/EducationResource/uploadUtils";
import { useState } from "react";
import { useSnackbar } from "../../Snackbar/SnackbarContext";

const ERROR_MESSAGE_UPLOADER_INIT =
  "There is an issue with the file uploader. Please refresh the page and try again. If the issue persists, contact customer support.";

interface useUploadResourceProps {
  onComplete: (resourceUrl: string) => any | Promise<any>;
}

export const useUploadResource = ({ onComplete }: useUploadResourceProps): [(url: string) => Promise<void>, boolean] => {
  const { setMessage } = useSnackbar();
  const uploadUrl = `${process.env.REACT_APP_URL_SCHEME}${process.env.REACT_APP_SERVER_DOMAIN}/upload-education-resource`;
  if (!uploadUrl) {
    setMessage("error", ERROR_MESSAGE_UPLOADER_INIT);
  }

  const [resourceUploading, setResourceUploading] = useState(false);
  const uploadResource = async (fileUrl: string) => {
    setResourceUploading(true);

    try {
      // Convert the base64 data to a Buffer and determine the file type
      const base64Data = Buffer.from(fileUrl.replace(/^data:.+;base64,/, ""), "base64");
      const fileType = await fileTypeFromBuffer(base64Data);

      // Throw error if the file type isn't recognized or is a video
      if (!fileType || fileType.mime.startsWith("video/")) {
        setMessage("error", "Unsupported file type");
        setResourceUploading(false);
        return;
      }

      const res = await axios.get(uploadUrl, {
        params: {
          fileExt: fileType.ext,
          fileMime: fileType.mime,
        },
        withCredentials: true,
      });

      const toUpload = await uploadResourceToAWS({ resData: res.data, fileBuffer: base64Data, fileType });

      await onComplete(toUpload);
      setResourceUploading(false);
    } catch (e) {
      logger.error({
        type: "misc",
        message: (e as Error).message,
      });
      setMessage("error", "Something went wrong!");
      setResourceUploading(false);
    }
  };

  return [uploadResource, resourceUploading];
};
