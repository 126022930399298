import { TableCell, TableRow } from "@mui/material";
import TablePage from "apps/web/src/components/universal/TablePage";
import { EducationResourceEventTableItemFragment } from "apps/web/src/types";
import { PaginationHooks } from "apps/web/src/utils/pagination";
import React from "react";
import { EducationResourceViewHistoryTableRows } from "./EducationResourceViewHistoryTableRows";

interface EducationResourceTableProps {
  paginationHooks: PaginationHooks;
  rows: readonly EducationResourceEventTableItemFragment[];
  loading: boolean;
  totalRows: number;
  timezone: string;
}

export const EducationResourceViewHistoryTable = ({ paginationHooks, loading, totalRows, rows, timezone }: EducationResourceTableProps) => {
  return (
    <TablePage
      size="medium"
      tableHeaderRow={
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Time</TableCell>
          <TableCell>Athlete Name</TableCell>
        </TableRow>
      }
      tableBodyRows={<EducationResourceViewHistoryTableRows rows={rows} timezone={timezone} />}
      paginationHooks={paginationHooks}
      loading={loading}
      total={totalRows}
    />
  );
};
