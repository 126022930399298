import React from "react";
import { ConfirmationDialog } from "../../../componentLibrary";

interface DeleteEducationResourceDialogProps {
  open: boolean;
  onCancel: () => void;
  onDelete: () => void;
}

export const DeleteEducationResourceDialog = ({ open, onCancel, onDelete }: DeleteEducationResourceDialogProps) => {
  return (
    <ConfirmationDialog
      open={open}
      title="Delete Resource"
      message="Are you sure you want to delete the file? Athletes that you have shared the file with will no longer be able to view it."
      onCancel={onCancel}
      onConfirm={onDelete}
      confirmLabel="Delete"
      variant="containedDestructive"
      messageSx={{ color: "text.primary" }}
    />
  );
};
