import { isNotemealProfileActive, teamworksProfileHasRequiredFields, UnlinkProfile } from "@notemeal/profile-sync";
import React from "react";
import ProfilesList from "./ProfilesList";

interface UnlinkProfilesListProps {
  profiles: readonly UnlinkProfile[];
  selectedId: string | null;
  onCancel: (profile: UnlinkProfile) => void;
  pendingArchive: (notemealId: string) => boolean;
}

export const UnlinkProfilesList = (props: UnlinkProfilesListProps) => {
  return (
    <ProfilesList
      {...props}
      title="Pending Unlinks"
      columnMode={"Cancel"}
      onCancel={props.onCancel}
      disableOnSelected
      profiles={props.profiles.map(p => ({ ...p, id: p.teamworks.id }))}
      getProfileForDisplay={({ teamworks, notemeal }) => ({
        firstName: teamworks.firstName,
        lastName: teamworks.lastName,
        email: teamworks.email,
        phoneNumber: teamworks.cellPhone,
        missingTeamworksRequiredFields: !teamworksProfileHasRequiredFields(teamworks),
        isArchived: notemeal ? !isNotemealProfileActive(notemeal) : false,
        pendingArchive: props.pendingArchive(notemeal.id),
        rolesString: notemeal?.__typename === "OrgMembership" ? notemeal.roles.join(", ") : "athlete",
      })}
    />
  );
};
