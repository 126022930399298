import { sortFnByFn } from "@notemeal/utils/sort";
import React from "react";
import { CardMode } from "../../../shared/DisplayCard";
import DisplayList, { RenderListItemArgs } from "../../../shared/DisplayList";
import { NotemealOnlyProfile } from "../../reducer";
import ProfileCard, {
  NotemealOnlyProfileForDisplay,
  PROFILE_CARD_ADDITIONAL_INFO_HEIGHT,
  PROFILE_CARD_HEIGHT,
} from "./NotemealOnlyProfileCard";

interface BaseNotemealOnlyListProps {
  profiles: readonly NotemealOnlyProfile[];
  onAdd?: (profile: NotemealOnlyProfile) => void;
  onLink?: (profile: NotemealOnlyProfile) => void;
  onEdit?: (profile: NotemealOnlyProfile) => void;
  onCancel?: (profile: NotemealOnlyProfile) => void;
  title: string;
  columnMode: CardMode;
  searchProps?: {
    searchTerm: string;
    setSearchTerm: (searchTerm: string) => void;
  };
  hasAdditionalProfileInfo?: boolean;
}

const getProfileForDisplay = (profile: NotemealOnlyProfile): NotemealOnlyProfileForDisplay =>
  profile.__typename === "Athlete"
    ? {
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        phoneNumber: profile.phoneNumber,
        isPending: !!profile.isPending,
        roles: ["athlete"],
      }
    : {
        firstName: profile.user.firstName,
        lastName: profile.user.lastName,
        email: profile.user.email,
        phoneNumber: profile.user.phoneNumber,
        isPending: !!profile.isPending,
        roles: profile.roles ? [...profile.roles] : [],
      };

const BaseNotemealOnlyList = ({
  profiles,
  searchProps,
  onAdd,
  onLink,
  onEdit,
  onCancel,
  title,
  columnMode,
  hasAdditionalProfileInfo,
}: BaseNotemealOnlyListProps) => {
  const renderListItem = ({ key, ...props }: RenderListItemArgs<NotemealOnlyProfile>) => (
    <ProfileCard
      onAdd={onAdd}
      onLink={onLink}
      onEdit={onEdit}
      onCancel={onCancel}
      getProfileForDisplay={getProfileForDisplay}
      key={key}
      {...props}
      hasAdditionalProfileInfo
    />
  );

  return (
    <DisplayList
      title={`${title} (${profiles.length})`}
      columnMode={columnMode}
      renderListItem={renderListItem}
      searchProps={searchProps}
      elements={[...profiles].sort(sortFnByFn(p => getProfileForDisplay(p).lastName || ""))}
      loading={false}
      selectedId={null}
      headerVariant="body1"
      virtualizeProps={{
        itemSize: hasAdditionalProfileInfo ? PROFILE_CARD_ADDITIONAL_INFO_HEIGHT : PROFILE_CARD_HEIGHT,
      }}
    />
  );
};

export default BaseNotemealOnlyList;
