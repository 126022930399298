import { Box, Checkbox, Dialog, DialogContent, Typography } from "@mui/material";
import Loading from "@notemeal/shared/ui/global/Loading";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useState } from "react";
import { useParams } from "react-router-dom-v5-compat";
import TablePage from "../../../../components/universal/TablePage";
import {
  TeamAthletesMealPlanFragment,
  useDeleteMealPlansMutation,
  useEditMealPlansIsSharedMutation,
  useMealPlansForTeamQuery,
} from "../../../../types";
import { useOffsetPagination } from "../../../../utils/pagination";
import MealPlanBulkCopyModal from "../../../../views/Athlete/MealPlans/BulkCopyDialog/Loader";
import BulkExportModal from "../../../../views/Team/Athletes/MealPlans/BulkExportModal";
import { TeamMealPlansTableHeader } from "../../../../views/Team/Athletes/MealPlans/TableHeader";
import MealPlanTableRow, { MealPlanTableHeaderRow } from "../../../../views/Team/Athletes/MealPlans/TableRow";
import { TeamAthletesMealPlanRow, formatMealPlans, getNextSelectionState } from "../../../../views/Team/Athletes/MealPlans/utils";
import { getNavOrgAthleteMealPlansMealPlan } from "../Athlete/AthletePaths";

export const TeamMealPlansPage = () => {
  const { teamId: maybeTeamId } = useParams();
  const teamId = maybeTeamId || "";

  const paginationHooks = useOffsetPagination();
  const { offset, limit, queryText, query, onChangeQueryText } = paginationHooks;
  const { data, loading, refetch } = useMealPlansForTeamQuery({
    variables: { teamId, input: { offset, limit }, query },
    fetchPolicy: "no-cache",
  });
  const { setMessage } = useSnackbar();
  const [displayBulkModal, setDisplayBulkModal] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [bulkShareModalOpen, setBulkShareModalOpen] = useState(false);
  const [bulkUnshareModalOpen, setBulkUnshareModalOpen] = useState(false);
  const [selectedMealPlanRowIds, setSelectedMealPlanRowIds] = useState<Set<string>>(new Set([]));
  const [mealPlanToBulkCopy, setMealPlanToBulkCopy] = useState<TeamAthletesMealPlanFragment | null>(null);

  const [deleteMealPlans, { loading: deleteInProgress }] = useDeleteMealPlansMutation({
    onCompleted: () => {
      setMessage("success", "Succesfully deleted meal plans!");
    },
    onError: e => {
      setMessage("error", "Failed to delete meal plans!");
    },
  });

  const [shareMealPlans] = useEditMealPlansIsSharedMutation();

  const handleUnshareMealPlans = async () => {
    await shareMealPlans({
      variables: {
        input: {
          mealPlanIds: [...selectedMealPlanRowIds],
          isShared: false,
        },
      },
    });
    setSelectedMealPlanRowIds(new Set([]));
  };

  const handleShareMealPlans = async () => {
    await shareMealPlans({
      variables: {
        input: {
          mealPlanIds: [...selectedMealPlanRowIds],
          isShared: true,
        },
      },
    });
    setSelectedMealPlanRowIds(new Set([]));
  };

  const handleDeleteMealPlans = async () => {
    await deleteMealPlans({
      variables: {
        input: {
          ids: [...selectedMealPlanRowIds],
        },
      },
    });
    setSelectedMealPlanRowIds(new Set([]));
    refetch();
  };

  const handleNavigateTo = ({ id: mealPlanId, athlete: { id: athleteId } }: TeamAthletesMealPlanRow) =>
    window.open(getNavOrgAthleteMealPlansMealPlan(athleteId, mealPlanId), "_blank");

  const deleteTitle = "Confirm Delete?";
  const deleteMessage = `This will permanently delete ${selectedMealPlanRowIds.size} meal plans. This action cannot be undone.`;

  const MAX_DELETION_LIMIT = 20;
  const deletionLimit = `You can only delete up to ${MAX_DELETION_LIMIT} meal plans at a time. Please try again with fewer meal plans selected.`;

  const bulkShareTitle = "Share Meal Plans?";
  const bulkShareMessage = `This will share ${selectedMealPlanRowIds.size} meal plans with athletes via mobile app.`;

  const bulkUnshareTitle = "Unshare Meal Plans?";
  const bulkUnshareMessage = `This will unshare ${selectedMealPlanRowIds.size} meal plans. They will be unavailable to the athlete on the mobile app.`;

  const rows = formatMealPlans(data?.mealPlanOffsetConnection.edges || []);

  const handleSelectRow = (row: TeamAthletesMealPlanRow) => {
    if (selectedMealPlanRowIds.has(row.id)) {
      const newSet = new Set([...selectedMealPlanRowIds].filter(id => id !== row.id));
      setSelectedMealPlanRowIds(newSet);
    } else {
      const newSet = new Set([...selectedMealPlanRowIds, row.id]);
      setSelectedMealPlanRowIds(newSet);
    }
  };

  const handleSelectAllVisibleRows = (rowsIds: string[], selectedRowIds: Set<string>) => {
    setSelectedMealPlanRowIds(getNextSelectionState(rowsIds, selectedRowIds));
  };

  const handleClickDeleteMealPlans = () => {
    if (selectedMealPlanRowIds.size > MAX_DELETION_LIMIT) {
      setMessage("error", deletionLimit);
      return;
    }
    setConfirmModalOpen(true);
  };
  const tableHeaderRow = (
    <MealPlanTableHeaderRow>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          onClick={() =>
            handleSelectAllVisibleRows(
              rows.map(r => r.id),
              selectedMealPlanRowIds
            )
          }
          indeterminate={rows.some(r => selectedMealPlanRowIds.has(r.id)) && !rows.every(r => selectedMealPlanRowIds.has(r.id))}
          checked={rows.every(r => selectedMealPlanRowIds.has(r.id))}
          inputProps={{
            "aria-label": "Select all plans",
          }}
        />
      </Box>
    </MealPlanTableHeaderRow>
  );

  const tableHeader = (
    <TeamMealPlansTableHeader
      selectedRowIds={[...selectedMealPlanRowIds]}
      queryText={queryText}
      setQueryText={onChangeQueryText}
      handleClickDelete={handleClickDeleteMealPlans}
      handleClickEmail={() => setDisplayBulkModal(true)}
      handleClickShare={() => setBulkShareModalOpen(true)}
      handleClickUnshare={() => setBulkUnshareModalOpen(true)}
    />
  );

  return (
    <>
      <TablePage
        header={tableHeader}
        tableHeaderRow={tableHeaderRow}
        tableBodyRows={
          !!data
            ? rows.map(row => (
                <MealPlanTableRow
                  row={row}
                  selected={selectedMealPlanRowIds.has(row.id)}
                  onSelectRow={() => handleSelectRow(row)}
                  handleClickCopy={mealPlanRow => setMealPlanToBulkCopy(mealPlanRow)}
                  handleNavigateTo={handleNavigateTo}
                />
              ))
            : null
        }
        paginationHooks={paginationHooks}
        loading={loading}
        total={data?.mealPlanOffsetConnection.pageInfo.total || 0}
      />
      {deleteInProgress && (
        <Dialog
          open={deleteInProgress}
          PaperProps={{
            sx: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: 4,
              boxShadow: 24,
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
        >
          <DialogContent>
            <Loading progressSize="md" />
            <Typography sx={{ marginTop: 2 }}>Deleting meal plans...</Typography>
          </DialogContent>
        </Dialog>
      )}
      {mealPlanToBulkCopy && (
        <MealPlanBulkCopyModal
          teamId={teamId}
          open={!!mealPlanToBulkCopy}
          onClose={() => setMealPlanToBulkCopy(null)}
          mealPlan={mealPlanToBulkCopy}
        />
      )}
      {displayBulkModal && (
        <BulkExportModal
          open={displayBulkModal}
          onClose={() => {
            setDisplayBulkModal(false);
            setSelectedMealPlanRowIds(new Set([]));
          }}
          previewMealPlanIds={[...selectedMealPlanRowIds]}
        />
      )}
      {confirmModalOpen && (
        <ConfirmationDialog
          open={confirmModalOpen}
          title={deleteTitle}
          message={deleteMessage}
          onCancel={() => setConfirmModalOpen(false)}
          onConfirm={() => {
            handleDeleteMealPlans();
            setConfirmModalOpen(false);
          }}
          variant="containedDestructive"
        />
      )}
      {bulkShareModalOpen && (
        <ConfirmationDialog
          open={bulkShareModalOpen}
          title={bulkShareTitle}
          message={bulkShareMessage}
          onCancel={() => setBulkShareModalOpen(false)}
          onConfirm={() => {
            handleShareMealPlans();
            setBulkShareModalOpen(false);
          }}
        />
      )}
      {bulkUnshareModalOpen && (
        <ConfirmationDialog
          open={bulkUnshareModalOpen}
          title={bulkUnshareTitle}
          message={bulkUnshareMessage}
          onCancel={() => setBulkUnshareModalOpen(false)}
          onConfirm={() => {
            handleUnshareMealPlans();
            setBulkUnshareModalOpen(false);
          }}
        />
      )}
    </>
  );
};
