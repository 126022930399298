import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";

import { AthleteEdsaResultsFragment, TeamPageTeamFragment } from "../../../../types";
import DashboardCard from "../Card";
import { DownloadButton } from "./DownloadButton";
import { EDSACardDisabled } from "./EDSACardDisabled";
import { useUser } from "apps/web/src/utils/tokens";
import { getOrgUserDetails } from "apps/web/src/pages/Auth/OrgUtils";

interface EDSACardProps {
  athletes: readonly AthleteEdsaResultsFragment[];
  team: TeamPageTeamFragment;
  enabled: boolean;
}

export const EDSACard = ({ athletes, team, enabled }: EDSACardProps) => {
  const user = useUser();
  const { hasRoleDietitian } = getOrgUserDetails(user);

  const exportButton = <DownloadButton data={athletes} teamName={team.name} />;
  return (
    <DashboardCard title="EDSA" options={hasRoleDietitian ? exportButton : null}>
      <Box sx={{ width: "100%", height: "100%" }}>
        {athletes && enabled ? (
          <Card>
            <CardContent sx={{ width: "100%", p: 2 }}>
              <Typography variant="body2">
                {athletes.filter(a => a.lastEdsaResult !== null).length}/{team.athletes.length} athletes have completed the{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://pmc.ncbi.nlm.nih.gov/articles/PMC7392177/">
                  EDSA
                </a>{" "}
                questionnaire
              </Typography>
            </CardContent>
          </Card>
        ) : (
          <EDSACardDisabled />
        )}
      </Box>
      <div />
    </DashboardCard>
  );
};
