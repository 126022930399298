import { AutocompleteRenderOptionState, Chip, createFilterOptions, FilterOptionsState, SxProps, Theme, Tooltip } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useCallback, useState } from "react";
import { BasicOption } from "../../../components/universal/BaseAsyncAutocomplete";
import { GetQueryVariablesFromPaginationAndInputArgs } from "../../../components/universal/InfiniteScroll/InfiniteScrollAutocomplete";
import { TeamPreviewFragment, useTeamCursorConnectionQuery } from "../../../types";
import BaseTagSelector, { BaseTagSelectorPassThroughProps } from "./BaseTagSelector";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: "3px",
      "&:last-of-type": {
        marginRight: theme.spacing(0),
      },
    },
  })
);

interface TeamSelectorProps extends BaseTagSelectorPassThroughProps {
  sx?: SxProps;
  selectedTeams: readonly TeamPreviewFragment[];
  onChange: (newTeams: TeamPreviewFragment[]) => void;
  filterTeams?: (edges: TeamPreviewFragment[]) => TeamPreviewFragment[];
  limitTags?: number;
  textInputLabel?: string;
  inputPlaceholder?: string;
}

const TeamSelector = ({
  sx,
  selectedTeams,
  onChange,
  filterTeams,
  limitTags,
  textInputLabel,
  inputPlaceholder,
  ...passTroughProps
}: TeamSelectorProps) => {
  const chipClasses = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const getQueryVariablesForTeam = useCallback(
    ({ cursor, limit, input }: GetQueryVariablesFromPaginationAndInputArgs) => ({
      variables: {
        query: input,
        pagination: { cursor, limit },
      },
    }),
    []
  );

  const transformAndFilterTeamOptions = (_edges: TeamPreviewFragment[] | undefined): TeamPreviewFragment[] => {
    const edges = _edges ?? [];
    return filterTeams ? filterTeams(edges) : edges;
  };
  const filterOptions = (teams: TeamPreviewFragment[], state: FilterOptionsState<TeamPreviewFragment>): TeamPreviewFragment[] =>
    createFilterOptions<TeamPreviewFragment>({
      stringify: () => state.inputValue,
    })(teams, state);

  const teamEdgesAreEqual = useCallback((edge1: TeamPreviewFragment, edge2: TeamPreviewFragment) => {
    return edge1.id === edge2.id;
  }, []);

  const handleChange = (_: string, teams: TeamPreviewFragment[]) => onChange(teams);

  const renderTags = (teams: TeamPreviewFragment[]) => {
    let visibleTeams = teams;
    let hiddenTeams = null;
    if (!isOpen && limitTags && teams.length > limitTags) {
      visibleTeams = teams.slice(0, limitTags);
      hiddenTeams = teams.slice(limitTags);
    }
    return (
      <>
        {visibleTeams.map(team => (
          <Chip
            key={team.id}
            onDelete={() => onChange(teams.filter(t => t.id !== team.id))}
            className={chipClasses.chip}
            label={team.name}
          />
        ))}
        {hiddenTeams && hiddenTeams.length > 0 && (
          <Tooltip title={hiddenTeams.map(({ name }) => name).join(", ")}>
            <Chip label={`+${hiddenTeams.length}`} className={chipClasses.chip} />
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <BaseTagSelector
      sx={sx}
      queryKey="teamCursorConnection"
      useCursorConnectionQuery={useTeamCursorConnectionQuery}
      getQueryVariablesFromPaginationAndInput={getQueryVariablesForTeam}
      transformAndFilterOptions={transformAndFilterTeamOptions}
      renderOption={(team: TeamPreviewFragment, _state: AutocompleteRenderOptionState) => <BasicOption element={team.name} />}
      handleChange={handleChange}
      getOptionLabel={(team: TeamPreviewFragment) => team.name}
      getCustomOptionKey={(team: TeamPreviewFragment) => team.id}
      filterOptions={filterOptions}
      noOptionsText="No Teams Found"
      inputPlaceholder={inputPlaceholder}
      textInputLabel={textInputLabel}
      edgesAreEqual={teamEdgesAreEqual}
      value={selectedTeams.concat()}
      renderTags={renderTags}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      {...passTroughProps}
    />
  );
};

export default TeamSelector;
