import { Box } from "@mui/material";
import React from "react";

import Loading from "@notemeal/shared/ui/global/Loading";

import TeamDashboardCard from "./Card";
import DisplaySettingsCard from "./DisplaySettings/Card";
import { EDSACard } from "./EDSA";
import GoalsSummaryCard from "./Goals/Card";
import NotificationsCard from "./Notifications/Card";
import ScheduleContent from "./Schedules/Content";
import ScheduleEmptyContent from "./Schedules/EmptyContent";

import { useHasFeature } from "@notemeal/shared/ui/Feature";
import { useUser } from "apps/web/src/utils/tokens";
import { format } from "date-fns";
import { TeamPageTeamFragment, useTeamDashboardQuery } from "../../../types";

interface TeamDashboardProps {
  teamId: string;
  team: TeamPageTeamFragment;
}

export const TeamDashboard = ({ teamId, team }: TeamDashboardProps) => {
  const hasRDRole = Boolean(useUser()?.orgMembership?.roles.includes("dietitian"));
  const { data, loading } = useTeamDashboardQuery({
    variables: {
      teamId,
      endDate: format(new Date(), "yyyy-mm-dd"),
      skipEDSA: !hasRDRole,
    },
    partialRefetch: true,
  });
  const hasEDSA = useHasFeature("eatingDisorderScreens");
  return (
    <Box sx={{ ml: 2, display: "flex", flexWrap: "wrap", alignContent: "flex-start" }}>
      {loading || !data ? (
        <TeamDashboardCard title="Notifications">
          <Loading progressSize="lg" />
        </TeamDashboardCard>
      ) : (
        <NotificationsCard teamId={teamId} teamNotificationSettings={data.team.foodLogNotificationSettings} />
      )}
      {loading || !data ? (
        <TeamDashboardCard title="Display Settings">
          <Loading progressSize="lg" />
        </TeamDashboardCard>
      ) : (
        <DisplaySettingsCard
          teamId={teamId}
          defaultExchangeDisplaySettings={data.team.defaultExchangeDisplaySettings}
          defaultMacroDisplaySettings={data.team.defaultMacroDisplaySettings}
          defaultNoTargetsDisplaySettings={data.team.defaultNoTargetsDisplaySettings}
          defaultHideAnthropometry={data.team.defaultHideAnthropometry}
          requestNewEdsaSurvey={data.team.requestNewEdsaSurvey}
        />
      )}
      {loading || !data ? (
        <TeamDashboardCard title="Goals">
          <Loading progressSize="lg" />
        </TeamDashboardCard>
      ) : (
        <GoalsSummaryCard teamGoalConfiguration={data.teamGoalConfiguration} team={team} />
      )}
      {hasEDSA &&
        hasRDRole &&
        (loading || !data?.team.athletes ? (
          <TeamDashboardCard title="EDSA">
            <Loading progressSize="lg" />
          </TeamDashboardCard>
        ) : (
          <EDSACard
            athletes={data.team.athletes}
            team={team}
            enabled={data.team.requestNewEdsaSurvey ? true : false} />
        ))}
      <TeamDashboardCard title="Schedules">
        {loading || !data ? (
          <Loading progressSize="lg" />
        ) : data.team.schedules.length ? (
          <ScheduleContent teamId={teamId} schedules={data.team.schedules} />
        ) : (
          <ScheduleEmptyContent teamId={teamId} />
        )}
      </TeamDashboardCard>
    </Box>
  );
};
