import { datadogRum } from "@datadog/browser-rum";
import axios from "axios";

export const useFetchResource = () => {
  const fetchUrl = `${process.env.REACT_APP_URL_SCHEME}${process.env.REACT_APP_SERVER_DOMAIN}/fetch-education-resource`;

  const fetchResource = async (fileKey: string) => {
    try {
      const response = await axios.get(fetchUrl, {
        params: { fileKey },
        withCredentials: true,
      });
      return response.data;
    } catch (err) {
      datadogRum.addError(`User encountered error while fetching resource from the S3 bucket`, { err });
    }
  };
  return fetchResource;
};
