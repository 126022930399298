import { datadogRum } from "@datadog/browser-rum";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DialogTitle from "../../componentLibrary/DialogTitle";
import { EducationResourceTableItemFragment } from "../../types";
import { useFetchResource } from "../universal/FileUpload/fetchResource";
import { getContentForFilePreview, getFileTypeForResource } from "./utils";

interface ResourcePreviewModalProps {
  educationResource: EducationResourceTableItemFragment;
  open: boolean;
  onClose: () => void;
}

export const ResourcePreviewModal = ({
  educationResource,
  open,
  onClose,
}: ResourcePreviewModalProps) => {
  const fetchResource = useFetchResource();
  const [fetchedFileUrl, setFetchedFileUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { name, fileKey } = educationResource;
  const handleFetchResource = async () => {
    setLoading(true);
    try {
      const fetchedFile = await fetchResource(fileKey);
      setFetchedFileUrl(fetchedFile.signedGetRequest);
    } catch (e) {
      datadogRum.addError(`User encountered error while fetching resource preview from the S3 bucket`, { e });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (fileKey) {
      handleFetchResource();
    }
  }, [fileKey]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { minWidth: "600px", maxWidth: "80%", height: "840px" },
      }}
    >
      <DialogTitle onClose={onClose} title="Preview File" />
      <DialogContent>
        {loading && <CircularProgress />}
        {fetchedFileUrl && getContentForFilePreview(name, fetchedFileUrl, fileKey)}
        <Box>
          <Typography variant="body1Medium">
            {name}.{getFileTypeForResource(fileKey)}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
