import { Box, Typography } from "@mui/material";
import React from "react";

export const EDSACardDisabled = () => {
  return (
    <Box sx={{ justifyContent: "center", alignItems: "center", flexDirection: "column", textAlign: "center" }}>
      <Typography variant="body2">EDSA disabled. Can be enabled in Display Settings.</Typography>
    </Box>
  );
};
