import { EducationResourceEventTableItemFragment } from "apps/web/src/types";

type Header = "First Name" | "Last Name" | "View Date" | "View Time" | "Timezone";

export interface SerializedExportRowForEducationResourceViewHistory {
  firstName: string;
  lastName: string;
  viewDate: string;
  viewTime: string;
  timezone: string;
}
interface ExportColumnEducationResourceViewHistoryDef {
  header: Header;
  key: keyof SerializedExportRowForEducationResourceViewHistory;
  width: number;
}

export const exportViewHistoryColumns: ExportColumnEducationResourceViewHistoryDef[] = [
  { header: "First Name", key: "firstName", width: 15 },
  { header: "Last Name", key: "lastName", width: 15 },
  { header: "View Date", key: "viewDate", width: 10 },
  { header: "View Time", key: "viewTime", width: 10 },
  { header: "Timezone", key: "timezone", width: 20 },
];

export const serializeViewHistoryRows = (
  rows: readonly EducationResourceEventTableItemFragment[],
  formatDateInTimezoneWithLocale: (datetime: string, timezone: string) => string,
  formatTimeInTimezoneWithLocale: (datetime: string, timezone: string) => string,
  timezone: string
): SerializedExportRowForEducationResourceViewHistory[] => {
  return rows.map(r => {
    return {
      firstName: r.createdBy.firstName,
      lastName: r.createdBy.lastName,
      viewDate: formatDateInTimezoneWithLocale(r.createdAt, timezone),
      viewTime: formatTimeInTimezoneWithLocale(r.createdAt, timezone),
      timezone,
    };
  });
};
