import { datadogRum } from "@datadog/browser-rum";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import React from "react";
import { FileOrImageDragAndDrop } from "../universal/FileUpload/FileOrImageDragAndDrop";
import { useDeleteResource } from "../universal/FileUpload/deleteResource";
import { useUploadResource } from "../universal/FileUpload/uploadResource";
import { getFileKeyFromUrl } from "./utils";

interface ResourceUploaderProps {
  fileKey: string;
  setFileKey: (fileKey: string) => void;
  setFileName: (fileName: string) => void;
  fileName: string;
  error: string | undefined;
}

export const ResourceUploader = ({ fileKey, fileName, setFileKey, setFileName, error }: ResourceUploaderProps) => {
  const [uploadResource, resourceUploading] = useUploadResource({
    onComplete: data => {
      const key = getFileKeyFromUrl(data);
      key && setFileKey(key);
    },
  });

  const deleteResource = useDeleteResource();

  const handleUpload = async (fileUrl: string, fileName: string) => {
    try {
      await uploadResource(fileUrl);
      setFileName(fileName);
    } catch (e) {
      datadogRum.addError(`User encountered error while uploading file`, { e });
    }
  };

  const handleDelete = async () => {
    if (fileName && fileKey) {
      await deleteResource(fileKey);
      setFileName("");
      setFileKey("");
    }
  };

  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(2) }}>
      {error && (
        <Typography variant="body2" sx={{ color: theme.palette.error.main }}>
          {error}
        </Typography>
      )}
      <FileOrImageDragAndDrop
        onUpload={handleUpload}
        fileType="resource"
        loading={resourceUploading}
        hasFile={!!fileName} />
      {fileKey && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: theme.palette.greyscale[200],
            border: `1px solid ${theme.palette.greyscale[200]} `,
            borderRadius: theme.spacing(0.5),
          }}
        >
          <Typography variant="body2" sx={{ p: theme.spacing(1) }}>
            {fileName}
          </Typography>
          <IconButton
            onClick={handleDelete}
            sx={{
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent",
              },
              color: theme.palette.grey[700],
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
