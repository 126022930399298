import FolderIcon from "@mui/icons-material/Folder";
import { Box } from "@mui/material";
import { PrimaryNavLink } from "@teamworksdev/nav";
import { NewChip } from "apps/web/src/components/universal/NewChip";
import React from "react";

// full page path
export const NAV_ORG_EDUCATION_RESOURCES = "/org/resources";

export const educationResourcesLink: PrimaryNavLink = {
  to: NAV_ORG_EDUCATION_RESOURCES,
  name: (
    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
      Resources
      <NewChip variant="nav" feature="resources" />
    </Box>
  ),
  icon: <FolderIcon />,
};
