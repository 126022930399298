import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, InputAdornment, TextField, useTheme } from "@mui/material";
import { SectionHeader } from "apps/web/src/componentLibrary/SectionHeader";
import { TWTabGroup } from "apps/web/src/componentLibrary/TWTabGroup/TWTabGroup";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { useOffsetPagination } from "apps/web/src/utils/pagination";

import { ResourceLabelSelect } from "apps/web/src/components/EducationResource/ResourceLabelSelect";
import { UploadedOrArchivedBySelect } from "apps/web/src/components/EducationResource/UpdatedOrArchivedBySelect";
import { UploadOrArchiveDateSelect } from "apps/web/src/components/EducationResource/UploadOrArchiveDateSelect";
import { CreateEducationResourceDialog } from "apps/web/src/views/EducationResources/Dialogs/CreateEducationResourceDialog";
import { EducationResourceTable } from "apps/web/src/views/EducationResources/EducationResourceTable";
import MenusTeamMultiSelect from "apps/web/src/views/Menus/SelectComponents/TeamMultiSelect";
import React, { useState } from "react";
import {
  CreateEducationResourceInput,
  FullEducationResourceTemplateFragment,
  TeamWithAthletesFragment,
  useCreateEducationResourceMutation,
  useEducationResourceCategoriesQuery,
  useEducationResourceOffsetConnectionQuery,
  useTeamsFilterForResourcesQuery,
  useUniqueEducationResourceUploadOrArchiveDatesCursorConnectionQuery,
} from "../../../../types";
import { ARCHIVED_RESOURCES, CURRENT_RESOURCES, getHeaderAndSubcopy } from "./utils";

const ROWS_PER_PAGE = 10;

export const EducationResourcesPage = () => {
  const theme = useTheme();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [viewMode, setViewMode] = useState(CURRENT_RESOURCES);
  const { setMessage } = useSnackbar();
  const paginationHooks = useOffsetPagination(ROWS_PER_PAGE);
  const [selectedTeams, setSelectedTeams] = useState<TeamWithAthletesFragment[]>([]);
  const [uploadedByMe, setUploadedByMe] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");

  const { limit, offset, queryText, onChangeQueryText, resetOffset } = paginationHooks;

  const isArchived = viewMode === ARCHIVED_RESOURCES;

  const { data, loading, refetch } = useEducationResourceOffsetConnectionQuery({
    variables: {
      pagination: { offset, limit },
      filterOptions: {
        searchText: queryText,
        isArchived,
        teamIds: selectedTeams.map(t => t.id),
        uploadedByMe,
        selectedDate,
        categoryId: selectedCategoryId,
      },
    },
    fetchPolicy: "no-cache",
  });

  const { data: teamsData } = useTeamsFilterForResourcesQuery();
  const allTeams = teamsData ? teamsData.teams : [];

  const educationResources = data?.educationResourceOffsetConnection.edges ?? [];

  const { data: uniqueDatesData } = useUniqueEducationResourceUploadOrArchiveDatesCursorConnectionQuery({
    variables: {
      input: {
        cursor: null,
        limit: 10,
      },
      isArchived,
    },
  });

  const { data: categoryData } = useEducationResourceCategoriesQuery();
  const categoryLabels = categoryData?.educationResourceCategories ?? [];

  const onSuccess = (educationResource: FullEducationResourceTemplateFragment, teams?: string[], athleteCount?: number) => {
    let successMessage = `${educationResource.name} has been uploaded`;
    if (teams?.length) {
      const teamNames = teams.join(", ");
      successMessage += ` and shared with ${teamNames}`;
    } else if (athleteCount !== undefined && educationResource.hasAdvancedSelection) {
      successMessage += ` and shared with ${athleteCount} athletes`;
    }
    setMessage("success", successMessage);
  };

  const [createEducationResource] = useCreateEducationResourceMutation({
    onCompleted: data => {
      const teamNames = (data.createEducationResource.educationResource.teams ?? []).map(team => team.name);
      const athleteCount = data.createEducationResource.educationResource.athleteCountForEducationResource;
      const educationResource = data.createEducationResource.educationResource;

      onSuccess(educationResource, teamNames, athleteCount);
      setCreateDialogOpen(false);
      refetch();
    },
  });

  const handleCreateResource = (input: CreateEducationResourceInput) => {
    createEducationResource({ variables: { input } });
  };

  const handleChangeTeams = (newSelectedTeams: readonly TeamWithAthletesFragment[]) => {
    setSelectedTeams([...newSelectedTeams]);
    resetOffset();
  };

  const handleChangeSelectedDate = (newSelectedDate: string) => {
    setSelectedDate(newSelectedDate);
    resetOffset();
  };

  const { header, subcopy } = getHeaderAndSubcopy(viewMode, queryText);

  const resetFilters = () => {
    setSelectedTeams([]);
    setSelectedCategoryId("");
    setUploadedByMe(false);
    setSelectedDate("");
    onChangeQueryText("");
  };

  return (
    <>
      {
        <>
          <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
            <Box sx={{ pt: theme.spacing(2) }}>
              <TWTabGroup
                tabs={[CURRENT_RESOURCES, ARCHIVED_RESOURCES]}
                onSelected={selected => {
                  setViewMode(selected);
                  resetFilters();
                }}
              />
            </Box>
            <Box sx={{ display: "flex", gap: 2, alignItems: "flex-end" }}>
              <MenusTeamMultiSelect
                allTeams={allTeams}
                selectedTeams={selectedTeams}
                onChange={teams => handleChangeTeams(teams)}
                sx={{ minWidth: 120 }}
                displayedTeamsCount={2}
              />
              <ResourceLabelSelect
                resourceLabels={categoryLabels}
                setSelectedCategoryId={setSelectedCategoryId}
                selectedCategoryId={selectedCategoryId}
              />
              <UploadedOrArchivedBySelect
                uploadedByMe={uploadedByMe}
                setUploadedByMe={setUploadedByMe}
                viewMode={viewMode} />
              <UploadOrArchiveDateSelect
                uniqueDates={uniqueDatesData?.uniqueEducationResourceUploadOrArchiveDatesCursorConnection.edges ?? []}
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
                handleChangeSelectedDate={handleChangeSelectedDate}
                viewMode={viewMode}
              />
              <TextField
                label="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                value={queryText}
                onChange={e => onChangeQueryText(e.target.value)}
              />
              {viewMode === CURRENT_RESOURCES && (
                <Button
                  onClick={() => {
                    setCreateDialogOpen(true);
                  }}
                >
                  Upload Resource
                </Button>
              )}
            </Box>
          </Box>
          {educationResources && educationResources.length > 0 ? (
            <EducationResourceTable
              paginationHooks={paginationHooks}
              rows={data?.educationResourceOffsetConnection.edges ?? []}
              loading={loading}
              totalRows={data?.educationResourceOffsetConnection.pageInfo.total ?? 0}
              archivedViewMode={isArchived}
              refetchOffsetConnection={refetch}
            />
          ) : (
            <Box sx={{ display: "flex", width: "100%", justifyContent: "center", pt: theme.spacing(20) }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: theme.spacing(55),
                  alignItems: "center",
                  textAlign: "center",
                  gap: theme.spacing(1),
                }}
              >
                <Box
                  sx={{
                    backgroundColor: theme.palette.secondary.light,
                    height: theme.spacing(6),
                    width: theme.spacing(6),
                    borderRadius: "50%",
                    display: "flex",
                    gap: theme.spacing(1),
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!queryText ? (
                    <FolderRoundedIcon
                      sx={{
                        height: theme.spacing(3),
                        width: theme.spacing(3),
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <SearchIcon sx={{ height: theme.spacing(3), width: theme.spacing(3), color: theme.palette.primary.main }} />
                  )}
                </Box>
                <SectionHeader header={header} subcopy={subcopy} />
              </Box>
            </Box>
          )}
        </>
      }
      {createDialogOpen && (
        <CreateEducationResourceDialog
          isOpen={createDialogOpen}
          onResourceUpload={handleCreateResource}
          onClose={() => setCreateDialogOpen(false)}
        />
      )}
    </>
  );
};
