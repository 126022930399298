import { MenuItem, TextField } from "@mui/material";
import React from "react";
import { CURRENT_RESOURCES } from "../../pages/Auth/Org/EducationResources/utils";

interface UploadedOrArchivedBySelectProps {
  uploadedByMe: boolean;
  setUploadedByMe: (uploadedByMe: boolean) => void;
  viewMode: string;
}

export const UploadedOrArchivedBySelect = ({ uploadedByMe, setUploadedByMe, viewMode }: UploadedOrArchivedBySelectProps) => {
  const label = viewMode === CURRENT_RESOURCES ? "Uploaded By" : "Archived By";

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedId = event.target.value;
    if (selectedId === "me") {
      setUploadedByMe(true);
    } else {
      setUploadedByMe(false);
    }
  };

  return (
    <TextField
      sx={{ minWidth: 100 }}
      select
      inputProps={{
        "aria-label": label,
      }}
      value={uploadedByMe === false ? "anyone" : "me"}
      onChange={handleChange}
      label={label}
    >
      <MenuItem value="anyone">Anyone</MenuItem>
      <MenuItem value="me">Me</MenuItem>
    </TextField>
  );
};
