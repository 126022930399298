import { Box } from "@mui/material";
import React from "react";
import ChipListPreview from "../../components/universal/ChipList/Preview";
import { AdvancedSelectionFragment, TeamFragment } from "../../types";
import ShareWithInfo from "../Tags/Dialogs/Mutation/AdvancedSelection/ShareWithInfo";
import {
  buildInitialCreateAdvancedSelectionStateFromTeams,
  buildInitialEditAdvancedSelectionState,
} from "../Tags/reducers/advancedSelectionReducers";

type EducationResourceSharingDetails = {
  id: string;
  teams: TeamFragment[];
  advancedSelection: AdvancedSelectionFragment | null;
  hasAdvancedSelection: boolean;
};
interface ShareWithCellProps {
  educationResource: EducationResourceSharingDetails;
}

export const ShareWithCell = (educationResource: ShareWithCellProps) => {
  const { teams, advancedSelection, hasAdvancedSelection } = educationResource.educationResource;

  const isNotAssigned = hasAdvancedSelection ? advancedSelection === null : teams === null || teams.length === 0;

  const isSharedWithTeams = teams && teams.length > 0;

  const isSharedWithAdvancedSelection = hasAdvancedSelection && advancedSelection !== null;

  const initialAssignedState = isSharedWithAdvancedSelection
    ? buildInitialEditAdvancedSelectionState(advancedSelection)
    : buildInitialCreateAdvancedSelectionStateFromTeams(teams);

  switch (true) {
    case isSharedWithTeams:
      return (
        <Box>
          {isSharedWithTeams && (
            <ChipListPreview
              items={teams.map(({ id, name }) => ({ id, label: name }))}
              hideItemsAfter={3}
              getToolTipText={hiddenItems => {
                const labels = hiddenItems.map(({ label }) => label);
                if (labels.length <= 1) {
                  // if there is only one team, return with no commas
                  return labels.join("");
                }
                // separate teams with commas, except for the last one, which is separated by "and"
                return `${labels.slice(0, -1).join(", ")} and ${labels[labels.length - 1]}`;
              }}
            />
          )}
        </Box>
      );

    case isSharedWithAdvancedSelection:
      return <ShareWithInfo
        state={initialAssignedState}
        maxChips={3}
        includeTrailingPeriod={false}
        showAdvancedSelectionClear={false} />;

    default:
      return <Box> {isNotAssigned && <span>Not Shared</span>}</Box>;
  }
};
