import { MenuItem, TextField } from "@mui/material";
import React from "react";
import { EducationResourceCategoryFragment } from "../../types";

interface ResourceLabelSelectProps {
  resourceLabels: readonly EducationResourceCategoryFragment[];
  setSelectedCategoryId: (categoryId: string) => void;
  selectedCategoryId: string;
}

export const ResourceLabelSelect = ({ resourceLabels, setSelectedCategoryId, selectedCategoryId }: ResourceLabelSelectProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedId = event.target.value;
    setSelectedCategoryId(selectedId);
  };

  return (
    <TextField
      select
      label="Label"
      sx={{ minWidth: 100 }}
      value={selectedCategoryId}
      onChange={handleChange}
      SelectProps={{
        renderValue: selected => (selected ? resourceLabels.find(label => label.id === selected)?.name : "Select label"),
      }}
    >
      <MenuItem value="">No Filter</MenuItem>

      {resourceLabels.map(label => (
        <MenuItem key={label.id} value={label.id}>
          {label.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
