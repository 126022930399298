import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import {
  Box,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { formatKcalOffset } from "@notemeal/shared/ui/MacroProtocol/utils";
import React from "react";
import { AssignMealPlanTemplateAthleteSelect } from "./AssignMealPlanTemplateAthleteSelect";
import { useAssignMealPlanTemplate } from "./AssignMealPlanTemplateContext";
import { AssignMealPlanTemplateDateAssignmentCalendar } from "./AssignMealPlanTemplateDateAssignmentCalendar";
import { AssignMealPlanTemplateDateAssignmentForm } from "./AssignMealPlanTemplateDateAssignmentForm";

export enum AssignMealPlanTemplateStep {
  NameAndType,
  DateAssignment,
  ChooseAthletes,
}

const stepLabels = ["Name and Type", "Date Assignment", "Choose Athletes"];

export const AssignMealPlanTemplateStepper = () => {
  const {
    step,
    name,
    setName,
    notes,
    setNotes,
    weightTargetTypeId,
    setWeightTargetTypeId,
    dateAssignment,
    setDateAssignment,
    isAutoSuggestionsEnabled,
    setIsAutoSuggestionsEnabled,
    athletes,
    setAthletes,
    goals,
  } = useAssignMealPlanTemplate();

  const {
    palette: { info },
  } = useTheme();

  if (!goals) {
    return <Typography>Template goals not found</Typography>;
  }

  return (
    <>
      <Stepper
        sx={{ p: 2, mb: 3, justifyContent: "center" }}
        activeStep={step}
        connector={<div />}>
        {stepLabels.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ px: 2 }}>
        {step === AssignMealPlanTemplateStep.NameAndType && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box>
              <Typography variant="h4" sx={{ mb: 0.5 }}>
                Template Details
              </Typography>
              <Typography variant="body2" sx={({ palette }) => ({ color: palette.text.secondary })}>
                Edit the name and add notes as needed
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2, flexGrow: 1 }}>
              <Box sx={{ maxWidth: "360px" }}>
                <TextField
                  fullWidth
                  autoFocus
                  label="Name"
                  inputProps={{ maxLength: 50 }}
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </Box>
              <Box sx={{ maxWidth: "720px" }}>
                <TextField
                  fullWidth
                  sx={{ flexGrow: 1 }}
                  label="Notes (optional)"
                  inputProps={{ maxLength: 2000 }}
                  value={notes ?? ""}
                  onChange={e => setNotes(e.target.value || null)}
                />
              </Box>
            </Box>
            {goals.length > 0 && (
              <>
                <Divider sx={{ my: 2 }} />
                <Box>
                  <Typography variant="h4" sx={{ mb: 0.5 }}>
                    Weight Target Type
                  </Typography>
                  <Typography variant="body2" sx={({ palette }) => ({ color: palette.text.secondary })}>
                    Select the weight target type to assign the athlete(s) to. The athlete's current type will get overridden if it differs
                    from the selected type.
                  </Typography>
                </Box>
                <Box>
                  <RadioGroup
                    value={weightTargetTypeId}
                    onChange={e => setWeightTargetTypeId(e.target.value)}
                    sx={{ display: "flex", gap: 2 }}
                  >
                    {goals.map(goal => (
                      <FormControlLabel
                        value={goal.id}
                        label={
                          <Box>
                            <Typography variant="body1">{goal.name}</Typography>
                            <Typography variant="body2" sx={({ palette }) => ({ color: palette.text.secondary })}>
                              {goal.goalType.name} | {formatKcalOffset(goal.kcalOffset)}
                            </Typography>
                          </Box>
                        }
                        control={<Radio size="small" />}
                      />
                    ))}
                  </RadioGroup>
                </Box>
              </>
            )}
          </Box>
        )}
        {step === AssignMealPlanTemplateStep.DateAssignment && (
          <Box sx={{ display: "flex", gap: 4 }}>
            <Box>
              <Box>
                <Typography variant="h4" sx={{ mb: 0.5 }}>
                  Date Assignment
                </Typography>
              </Box>
              <AssignMealPlanTemplateDateAssignmentForm
                calendarLocation="below"
                startDateInPastBehavior="error"
                dateAssignment={dateAssignment}
                setDateAssignment={setDateAssignment}
              />

              <Box sx={{ mt: 3, mb: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1, pb: 1 }}>
                  <AutoFixHighOutlinedIcon sx={{ width: 20, height: 20 }} />
                  <Typography variant="body1Medium">Time Suggestions</Typography>
                </Box>

                <Typography variant="body2" sx={({ palette }) => ({ color: palette.text.secondary })}>
                  Enable this to allow auto-accepting of all suggestions
                </Typography>
              </Box>
              <Box
                sx={{
                  p: 1,
                  backgroundColor: "grey.100",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                  <Typography variant="body1Semibold">Auto-accept all suggestions</Typography>
                  <Typography variant="subtitle1">For meals on all applicable days/weeks</Typography>
                </Box>
                <Switch checked={isAutoSuggestionsEnabled} onChange={() => setIsAutoSuggestionsEnabled(!isAutoSuggestionsEnabled)} />
              </Box>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box sx={{ py: 2 }}>
              <AssignMealPlanTemplateDateAssignmentCalendar
                dateAssignment={dateAssignment}
                setDateAssignment={setDateAssignment}
                mealPlanColor={info.main}
                onClickCalendarDate={() => {}}
                otherMealPlanDateAssignments={[]}
              />
            </Box>
          </Box>
        )}
        {step === AssignMealPlanTemplateStep.ChooseAthletes && (
          <Box>
            <AssignMealPlanTemplateAthleteSelect athletes={athletes} setAthletes={setAthletes} />
          </Box>
        )}
      </Box>
    </>
  );
};
