import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { NotemealOnlyState, NotemealProfile } from "@notemeal/profile-sync";
import { sortByFn } from "@notemeal/utils/sort";
import React, { Dispatch, useMemo } from "react";
import ListsColumn from "../../shared/ListColumn";
import ListsContainer from "../../shared/ListsContainer";
import { TeamworksProfilesAction } from "../reducer";
import NotemealOnlyProfileList from "./DisplayLists/NotemealOnlyProfileList";
import UnlinkedNotemealOnlyProfileList from "./DisplayLists/UnlinkedNotemealOnlyProfileList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: "calc(100% - 64px)",
    },
    content: {
      display: "flex",
      flexDirection: "column",
    },
    listColumn: {
      overflow: "hidden",
    },
    subtext: { paddingLeft: 1 },
  })
);

// TODO: Need to include athletes here
interface NotemealOnlyDialogProps {
  open: boolean;
  onClose: () => void;
  unlinkedNotemealProfiles: readonly NotemealProfile[];
  notemealOnlyState: NotemealOnlyState;
  dispatch: Dispatch<TeamworksProfilesAction>;
}

const NotemealOnlyDialog = ({ open, onClose, unlinkedNotemealProfiles, notemealOnlyState, dispatch }: NotemealOnlyDialogProps) => {
  const classes = useStyles();

  const handleAddNotemealOnly = (profile: NotemealProfile) => {
    if (profile.__typename === "Athlete") {
      dispatch({
        type: "ADD_NOTEMEAL_ONLY_ATHLETE_ACTION",
        payload: profile,
      });
    } else {
      dispatch({
        type: "ADD_NOTEMEAL_ONLY_ORG_MEMBERSHIP_ACTION",
        payload: profile,
      });
    }
  };
  const handleRemoveNotemealOnly = (profile: NotemealProfile) => {
    if (profile.__typename === "Athlete") {
      dispatch({
        type: "REMOVE_NOTEMEAL_ONLY_ATHLETE_ACTION",
        payload: profile,
      });
    } else {
      dispatch({
        type: "REMOVE_NOTEMEAL_ONLY_ORG_MEMBERSHIP_ACTION",
        payload: profile,
      });
    }
  };

  const notemealOnlyProfiles = useMemo(
    () =>
      sortByFn([...notemealOnlyState.notemealOnlyOrgMemberships, ...notemealOnlyState.notemealOnlyAthletes], item => {
        return item.__typename === "Athlete" ? item.lastName + item.firstName : item.user.lastName + item.user.firstName;
      }),
    [notemealOnlyState.notemealOnlyOrgMemberships, notemealOnlyState.notemealOnlyAthletes]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      classes={{ paper: classes.paper }}>
      <DialogTitle>
        Select Notemeal Only Profiles
        <Typography variant="body1Medium" className={classes.subtext}>
          Profile that should not be synced with Teamworks
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <ListsContainer>
          <ListsColumn className={classes.listColumn}>
            <UnlinkedNotemealOnlyProfileList onAdd={handleAddNotemealOnly} profiles={unlinkedNotemealProfiles} />
          </ListsColumn>

          <ListsColumn className={classes.listColumn}>
            <NotemealOnlyProfileList profiles={notemealOnlyProfiles} onCancel={handleRemoveNotemealOnly} />
          </ListsColumn>
        </ListsContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotemealOnlyDialog;
