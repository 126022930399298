import { LinkedProfile, teamworksProfileHasRequiredFields } from "@notemeal/profile-sync";
import React from "react";
import ProfilesList from "./ProfilesList";

interface PendingProfilesListProps {
  profiles: LinkedProfile[];
  onCancel: (profile: LinkedProfile) => void;
  selectedId: string | null;
}

const PendingProfilesList = (props: PendingProfilesListProps) => {
  const { selectedId, profiles } = props;

  return (
    <ProfilesList
      {...props}
      title="Pending Profiles"
      columnMode={selectedId ? "NoAction" : "Cancel"}
      disableOnSelected
      profiles={profiles.map(p => ({
        ...p,
        id: p.teamworks.id,
        roles: p.notemeal?.__typename === "OrgMembership" ? p.notemeal.roles.join(", ") : "athlete",
      }))}
      getProfileForDisplay={({ teamworks, roles }) => ({
        firstName: teamworks.firstName,
        lastName: teamworks.lastName,
        email: teamworks.email,
        phoneNumber: teamworks.cellPhone,
        missingTeamworksRequiredFields: !teamworksProfileHasRequiredFields(teamworks),
        rolesString: roles,
      })}
    />
  );
};

export default PendingProfilesList;
