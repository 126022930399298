import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Dialog, DialogActions, DialogContent, Switch, Typography } from "@mui/material";
import React, { useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import DialogTitle from "../../../componentLibrary/DialogTitle";
import DiscardChangesDialog from "../../../components/universal/DiscardChangesDialog";
import { useDeleteResource } from "../../../components/universal/FileUpload/deleteResource";
import { CreateEducationResourceInput } from "../../../types";
import { EducationResourceForm } from "./../EducationResourceForm";
import {
  EducationResourceFormSchema,
  EducationResourceFormType,
  educationResourceFormDefaultValues,
  resourceFormToCreateInput,
} from "./../EducationResourceSchema";
import { getEmptyShareState, sharingReducer } from "./../Sharing";

interface CreateEducationResourceDialogProps {
  isOpen: boolean;
  onResourceUpload: (input: CreateEducationResourceInput) => void;
  onClose: () => void;
}

export const CreateEducationResourceDialog = ({ isOpen, onResourceUpload, onClose }: CreateEducationResourceDialogProps) => {
  const [discardChangesOpen, setDiscardChangesOpen] = useState(false);
  const [fileKey, setFileKey] = useState<string>("");
  const [fileName, setFileName] = useState("");
  const [isShared, setIsShared] = useState(false);
  const [shareState, shareDispatch] = useReducer(sharingReducer, getEmptyShareState());
  // need our own dirty state for using advanced selection b/c it's not part of the form
  const [formIsDirty, setFormIsDirty] = useState(false);

  const deleteResource = useDeleteResource();

  const form = useForm<EducationResourceFormType>({
    defaultValues: educationResourceFormDefaultValues(),
    resolver: zodResolver(EducationResourceFormSchema),
    mode: "onChange",
  });

  const { isDirty } = form.formState;

  const handleClose = () => {
    if (isDirty || formIsDirty) {
      setDiscardChangesOpen(true);
    } else {
      onClose();
    }
  };

  const handleSave = (resource: EducationResourceFormType) => {
    resource.isShared = isShared;

    const input = resourceFormToCreateInput(resource, shareState);

    onResourceUpload(input);
    onClose();
  };

  const handleDiscardChanges = async () => {
    if (fileKey) {
      await deleteResource(fileKey); // Delete file from S3
      setFileKey(""); // Clear fileKey after deletion
    }
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="md">
      <DialogTitle title={"Upload Resource"} onClose={handleClose} />
      <DialogContent sx={{ pt: 0, minWidth: "800px" }}>
        <EducationResourceForm
          form={form}
          mode={"create"}
          fileName={fileName}
          setFileName={setFileName}
          shareState={shareState}
          shareDispatch={shareDispatch}
          setFormIsDirty={setFormIsDirty}
        />
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Switch
            size="medium"
            inputProps={{
              "aria-label": "Share resource with athletes",
            }}
            onClick={e => e.stopPropagation()}
            onChange={e => {
              setIsShared(e.target.checked);
            }}
          />
          <Typography variant="body1">Share with athletes</Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "8px" }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={form.handleSubmit(handleSave)}>Upload</Button>
        </Box>
      </DialogActions>
      {discardChangesOpen && (
        <DiscardChangesDialog
          open={discardChangesOpen}
          onClose={() => {
            setDiscardChangesOpen(false);
          }}
          onDiscard={handleDiscardChanges}
        />
      )}
    </Dialog>
  );
};
