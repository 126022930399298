import React from "react";
import { Box, Typography } from "@mui/material";
import Icon from "@mui/material/Icon";
import WarningRounded from "@mui/icons-material/WarningRounded";
import CheckCircleRounded from "@mui/icons-material/CheckCircleRounded";
import ErrorRounded from "@mui/icons-material/ErrorRounded";

type Info = {
  title: string;
  description: string;
  icon: string;
  iconColor: string;
  bgColor: string;
};

const getInfoFromScore = (isEdsaPending: boolean, athleteName: string, score?: number, date?: string): Info => {
  if (isEdsaPending || !score) {
    return {
      title: "EDSA Not Completed",
      description: `${athleteName} has not completed the Eating Disorders Screen for Athletes (EDSA)`,
      icon: "warning",
      iconColor: "#EF6820",
      bgColor: "#FEF4E6",
    };
  }
  const formattedDate = date ? new Date(date).toLocaleDateString() : "N/A";
  return {
    title: score < 3 ? "EDSA Completed" : "EDSA Score Warning",
    description: `${athleteName} scored a ${score} on the Eating Disorders Screen for Athletes (EDSA) on ${formattedDate}`,
    icon: score < 3 ? "done" : "error",
    iconColor: score < 3 ? "#0A893C" : "#D83D32",
    bgColor: score < 3 ? "#E7F3EC" : "#FBECEB",
  };
};

interface EDSAStatusProps {
  isEdsaPending: boolean;
  athleteName: string;
  score?: number;
  date?: string;
}

export const EDSAStatus = ({ isEdsaPending, athleteName, score, date }: EDSAStatusProps) => {
  const info = getInfoFromScore(isEdsaPending, athleteName, score, date);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        bgcolor: info.bgColor,
        gap: 1,
        p: 2,
        borderRadius: 1,
        overflow: "visible",
      }}
    >
      <Box>
        <Icon sx={{ color: info.iconColor }}>
          {info.icon === "warning" ? (
            <WarningRounded sx={{ width: 22, height: 22 }} />
          ) : info.icon === "done" ? (
            <CheckCircleRounded sx={{ width: 22, height: 22 }} />
          ) : (
            <ErrorRounded sx={{ width: 22, height: 22 }} />
          )}
        </Icon>
      </Box>
      <Box>
        <Typography variant="h4">{info.title}</Typography>
        <Typography>{info.description}</Typography>
      </Box>
    </Box>
  );
};
