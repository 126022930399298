export const CURRENT_RESOURCES = "Current Resources";
export const ARCHIVED_RESOURCES = "Archived Resources";

const NO_CURRENT_RESOURCES_HEADER = "No resources";
const NO_ARCHIVED_RESOURCES_HEADER = "No archived resources";
const NO_RESULTS_HEADER = "No results";

const NO_CURRENT_RESOURCES_COPY = `There are no resources yet. Get started by uploading and sharing a resource today.`;
const NO_ARCHIVED_RESOURCES_COPY = `There aren't any resources that have been archived yet.`;
const NO_RESULTS_COPY = `Looks like there are no results. Please adjust your search criteria and try again.`;

export const getHeaderAndSubcopy = (viewMode: string, queryText: string | null) => {
  if (viewMode === CURRENT_RESOURCES) {
    if (queryText) {
      return {
        header: NO_RESULTS_HEADER,
        subcopy: NO_RESULTS_COPY,
      };
    }
    return {
      header: NO_CURRENT_RESOURCES_HEADER,
      subcopy: NO_CURRENT_RESOURCES_COPY,
    };
  } else if (viewMode === ARCHIVED_RESOURCES) {
    if (queryText) {
      return {
        header: NO_RESULTS_HEADER,
        subcopy: NO_RESULTS_COPY,
      };
    }
  }
  return {
    header: NO_ARCHIVED_RESOURCES_HEADER,
    subcopy: NO_ARCHIVED_RESOURCES_COPY,
  };
};
