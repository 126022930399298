import { Box, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material";
import React, { Dispatch } from "react";
import SelectWithPlaceHolder from "../../../../../components/universal/SelectWithPlaceHolder";
import NamedTagSelector from "../../../Selectors/NamedTagSelector";
import PositionSelector from "../../../Selectors/PositionSelector";
import TeamSelector from "../../../Selectors/TeamSelector";
import { SmartTagAction, SmartTagState, SmartTagStateType } from "../../../reducers/smartTagReducers";
import { EditNameAction } from "../../../reducers/tagReducers";

interface SmartTagContentProps {
  state: SmartTagState;
  tagName: String;
  dispatch: Dispatch<SmartTagAction | EditNameAction>;
  tagId: string | null;
}

const attributeOptions: SmartTagStateType[] = ["Position", "Tag", "Team"];

const SmartTagContent = ({ state, dispatch, tagName, tagId }: SmartTagContentProps) => {
  const {
    palette: { mode, greyscale },
  } = useTheme();
  const handleAttributeChange = (payload: SmartTagStateType) => {
    dispatch({
      type: "SWITCH_TYPE_ACTION",
      payload,
    });
  };
  return (
    <>
      <Typography variant="h3" sx={{ mb: 2 }}>
        Set Logic
      </Typography>
      <Box sx={{ p: 2, border: `1px solid ${greyscale[400]}`, borderRadius: "5px" }}>
        <Box sx={{ px: 2, pb: 2, background: mode === "light" ? greyscale[100] : greyscale[700], borderRadius: "5px" }}>
          <Box sx={{ py: 2, display: "flex", alignItems: "center", gap: 2 }}>
            <Typography sx={{ whiteSpace: "nowrap" }}> If the athlete's </Typography>
            <SelectWithPlaceHolder
              sx={{ width: "130px" }}
              value={state.type === "Empty" ? "" : state.type}
              placeholderText="Attribute"
              onChange={e => handleAttributeChange(e.target.value as SmartTagStateType)}
              isEmpty={state.type === "Empty"}
            >
              {attributeOptions.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </SelectWithPlaceHolder>
            <Typography sx={{ whiteSpace: "nowrap" }}> is any of </Typography>
            {state.type === "Empty" && (
              <Select
                sx={{ width: "100%" }}
                disabled={true}
                displayEmpty
                renderValue={() => "Select Attribute First"} />
            )}
            {state.type === "Position" && (
              <PositionSelector
                sx={{ mt: 0, width: "100%" }}
                selectedPositions={state.positions}
                onChange={newPositions => dispatch({ type: "EDIT_POSITIONS_ACTION", payload: newPositions })}
              />
            )}
            {state.type === "Tag" && (
              <NamedTagSelector
                sx={{ mt: 0, width: "100%" }}
                selectedNamedTags={state.tags}
                onChange={newTags => dispatch({ type: "EDIT_TAGS_ACTION", payload: newTags })}
                filterNamedTags={edges => edges.filter(({ tag: { id } }) => tagId !== id)}
              />
            )}
            {state.type === "Team" && (
              <TeamSelector
                sx={{ mt: 0, width: "100%" }}
                inputPlaceholder="Teams"
                selectedTeams={state.teams}
                onChange={newTeams => dispatch({ type: "EDIT_TEAMS_ACTION", payload: newTeams })}
              />
            )}
          </Box>
          <Box sx={{ py: 2, display: "flex", alignItems: "center", gap: 2 }}>
            <Typography sx={{ whiteSpace: "nowrap" }}> then assign the tag: </Typography>{" "}
            <TextField
              sx={{ mt: 0 }}
              value={tagName}
              onChange={e =>
                dispatch({
                  type: "EDIT_NAME_ACTION",
                  payload: e.target.value,
                })
              }
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SmartTagContent;
