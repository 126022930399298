import axios from "axios";
import { Buffer } from "buffer";

interface uploadResourceProps {
  resData: { signedPutRequest: string; url: string };
  fileBuffer: Buffer;
  fileType?: { mime: string; ext: string };
}

export const uploadResourceToAWS = async ({ resData, fileBuffer, fileType }: uploadResourceProps) => {
  const { signedPutRequest, url } = resData;

  await axios.put(signedPutRequest, fileBuffer, {
    headers: {
      "Content-Type": fileType ? fileType.mime : "application/octet-stream",
      Accept: "*/*",
      "Content-Encoding": "base64",
    },
  });

  return url;
};
