import { TableCell, TableRow } from "@mui/material";
import React from "react";
import TablePage from "../../components/universal/TablePage";
import { EducationResourceTableItemFragment } from "../../types";
import { PaginationHooks } from "../../utils/pagination";
import { EducationResourceTableRows } from "./EducationResourceRows";

interface EducationResourceTableProps {
  paginationHooks: PaginationHooks;
  rows: readonly EducationResourceTableItemFragment[];
  loading: boolean;
  totalRows: number;
  archivedViewMode: boolean;
  refetchOffsetConnection: () => void;
}

export const EducationResourceTable = ({
  paginationHooks,
  loading,
  totalRows,
  rows,
  archivedViewMode,
  refetchOffsetConnection,
}: EducationResourceTableProps) => {
  return (
    <TablePage
      tableHeaderRow={
        archivedViewMode ? (
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Shared With</TableCell>
            <TableCell>Archived By</TableCell>
            <TableCell>Archive Date</TableCell>
          </TableRow>
        ) : (
          <TableRow>
            <TableCell padding="checkbox">Shared</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Label</TableCell>
            <TableCell>Share With</TableCell>
            <TableCell>Uploaded By</TableCell>
            <TableCell>Uploaded Date</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        )
      }
      tableBodyRows={<EducationResourceTableRows rows={rows} refetchOffsetConnection={refetchOffsetConnection} />}
      paginationHooks={paginationHooks}
      loading={loading}
      total={totalRows}
    />
  );
};
