import React from "react";
import ExcelJS from "exceljs";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import { Button } from "@mui/material";
import { AthleteEdsaResultsFragment } from "../../../../types";

enum EdsaQuestions {
  q1 = "Does your weight, shape, or body composition affect the way you feel about yourself?",
  q2 = "Are you dissatisfied with your weight, shape, or body composition?",
  q3 = "Do you worry that your weight, shape, or body composition will change if you cannot exercise?",
  q4 = "Do you want to be leaner even if others may think you are already lean?",
  q5 = "Do you worry about losing control over your eating because of how it may affect your weight, shape, or body composition?",
  q6 = "Do you try to avoid certain foods to influence your weight, shape, or body composition?",
}

interface CSVData {
  "Athlete Name": string;
  [EdsaQuestions.q1]: number;
  [EdsaQuestions.q2]: number;
  [EdsaQuestions.q3]: number;
  [EdsaQuestions.q4]: number;
  [EdsaQuestions.q5]: number;
  [EdsaQuestions.q6]: number;
  score: number;
  date: string;
}

interface DownloadButtonProps {
  data: readonly AthleteEdsaResultsFragment[];
  teamName: string;
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({ data, teamName }) => {
  const athletesWithResults = data.filter(a => a.lastEdsaResult !== null);
  const downloadXLSX = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    const formatData = (athlete: AthleteEdsaResultsFragment): CSVData => ({
      "Athlete Name": `${athlete.lastName}, ${athlete.firstName}`,
      [EdsaQuestions.q1]: athlete.lastEdsaResult!.q1Answer,
      [EdsaQuestions.q2]: athlete.lastEdsaResult!.q2Answer,
      [EdsaQuestions.q3]: athlete.lastEdsaResult!.q3Answer,
      [EdsaQuestions.q4]: athlete.lastEdsaResult!.q4Answer,
      [EdsaQuestions.q5]: athlete.lastEdsaResult!.q5Answer,
      [EdsaQuestions.q6]: athlete.lastEdsaResult!.q6Answer,
      score: athlete.lastEdsaResult!.score,
      date: new Date(athlete.lastEdsaResult!.createdAt).toISOString().split("T")[0],
    });
    const serializedData = athletesWithResults.map(formatData);
    const headers = Object.keys(serializedData[0]);

    worksheet.columns = headers.map(header => ({
      header,
      key: header,
      width: Math.max(header.length, ...serializedData.map(row => row[header as keyof CSVData]?.toString().length || 10)) + 2,
    }));

    serializedData.forEach(row => {
      worksheet.addRow(headers.map(header => row[header as keyof CSVData]));
    });

    const fileName = `edsa_results_for_${teamName}_export_date_${format(new Date(), "yyyy-MM-dd")}`;
    saveAs(new Blob([await workbook.xlsx.writeBuffer()]), fileName + ".xlsx");
  };
  return (
    <Button
      variant="text"
      size="small"
      onClick={downloadXLSX}
      aria-label="Export Team EDSA Results"
      disabled={athletesWithResults.length < 1}
    >
      Export
    </Button>
  );
};
