import { Paper, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { ReactNode } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      boxSizing: "border-box",
      marginBottom: theme.spacing(3),
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
      width: `calc(50% - ${theme.spacing(3)})`,
      height: 350,
      display: "flex",
      flexDirection: "column",
    },
    header: {
      display: "flex",
      alignItems: "baseline",
      gap: theme.spacing(),
    },
    spacing: {
      flexGrow: 1,
    },
    children: {
      marginTop: theme.spacing(),
      flexGrow: 1,
      overflow: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

interface TeamDashboardCardProps {
  title: string;
  options?: ReactNode;
  subtitle?: string;
  children: ReactNode;
}

const TeamDashboardCard = ({ title, children, options, subtitle }: TeamDashboardCardProps) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root} elevation={3}>
      <div className={classes.header}>
        <Typography variant="h3" aria-label={title}>
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="subtitle1" aria-label={subtitle}>
            {subtitle}
          </Typography>
        )}
        <div className={classes.spacing} />
        {options}
      </div>
      <div className={classes.children}>{children}</div>
    </Paper>
  );
};

export default TeamDashboardCard;
