import { EducationResourceTableItemFragment } from "../../types";
import {
  buildInitialCreateAdvancedSelectionState,
  buildInitialCreateAdvancedSelectionStateFromTeams,
  buildInitialState,
} from "../Tags/reducers/advancedSelectionReducers";
import { ShareState } from "./types";

export const getResourceInfo = (resource: EducationResourceTableItemFragment) => {
  return {
    id: resource.id,
    teams: resource.teams ? [...resource.teams] : [],
    advancedSelection: resource.advancedSelection,
    hasAdvancedSelection: resource.hasAdvancedSelection,
  };
};

export const getInitialState = (mode: string, shareState: ShareState, initialShareValuesForEdit?: EducationResourceTableItemFragment) => {
  if (mode === "create") {
    if (shareState.__typename === "Teams") {
      return buildInitialCreateAdvancedSelectionStateFromTeams(shareState.teams);
    }
    return buildInitialCreateAdvancedSelectionState();
  }

  if (mode === "edit") {
    if (initialShareValuesForEdit?.advancedSelection) {
      return buildInitialState(initialShareValuesForEdit.advancedSelection);
    }
    if (initialShareValuesForEdit?.teams) {
      return buildInitialCreateAdvancedSelectionStateFromTeams(initialShareValuesForEdit.teams);
    }
    return buildInitialCreateAdvancedSelectionState();
  }

  return buildInitialCreateAdvancedSelectionState();
};
