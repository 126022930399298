import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Dialog, DialogActions, DialogContent, Switch, Typography } from "@mui/material";
import React, { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import DialogTitle from "../../../componentLibrary/DialogTitle";
import DiscardChangesDialog from "../../../components/universal/DiscardChangesDialog";
import { useDeleteResource } from "../../../components/universal/FileUpload/deleteResource";
import { EditEducationResourceInput, EducationResourceTableItemFragment } from "../../../types";
import { EducationResourceForm } from "../EducationResourceForm";
import {
  EducationResourceFormSchema,
  EducationResourceFormType,
  educationResourceFormDefaultValues,
  resourceFormToCreateInput,
} from "../EducationResourceSchema";
import { getEmptyShareState, sharingReducer } from "../Sharing";

interface EditEducationResourceDialogProps {
  isOpen: boolean;
  onResourceUpload: (input: EditEducationResourceInput) => void;
  onClose: () => void;
  initialValues: EducationResourceTableItemFragment;
}

export const EditEducationResourceDialog = ({ isOpen, onResourceUpload, onClose, initialValues }: EditEducationResourceDialogProps) => {
  const [discardChangesOpen, setDiscardChangesOpen] = useState(false);
  const [fileKey, setFileKey] = useState<string>("");
  const [fileName, setFileName] = useState("");
  const [isShared, setIsShared] = useState(initialValues.isShared);
  const [shareState, shareDispatch] = useReducer(sharingReducer, getEmptyShareState());
  // we need our own dirty state for using advanced selection b/c it's not part of the form
  const [formIsDirty, setFormIsDirty] = useState(false);
  const deleteResource = useDeleteResource();

  useEffect(() => {
    setFileKey(initialValues.fileKey);
    setFileName(initialValues.name);
  }, []);

  const form = useForm<EducationResourceFormType>({
    defaultValues: educationResourceFormDefaultValues(initialValues),
    resolver: zodResolver(EducationResourceFormSchema),
    mode: "onChange",
  });

  const { isDirty } = form.formState;

  const handleClose = () => {
    if (isDirty || formIsDirty) {
      setDiscardChangesOpen(true);
    } else {
      onClose();
    }
  };

  const handleSave = (resource: EducationResourceFormType) => {
    const resourceWithId = {
      ...resource,
      id: initialValues.id,
    };

    const input = {
      ...resourceFormToCreateInput(resourceWithId, shareState),
      id: initialValues.id,
      isShared,
    };

    onResourceUpload(input);
    onClose();
  };

  const handleDiscardChanges = async () => {
    if (fileKey) {
      await deleteResource(fileKey); // Deletes file from S3
      setFileKey("");
    }
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle title={"Edit Resource"} onClose={handleClose} />
      <DialogContent sx={{ pt: 0 }}>
        <EducationResourceForm
          form={form}
          mode="edit"
          fileName={fileName}
          setFileName={setFileName}
          shareState={shareState}
          initialShareValuesForEdit={initialValues}
          shareDispatch={shareDispatch}
          setFormIsDirty={setFormIsDirty}
        />
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Switch
            size="medium"
            inputProps={{
              "aria-label": "Share resource with athletes",
            }}
            checked={isShared}
            onClick={e => e.stopPropagation()}
            onChange={e => {
              setIsShared(e.target.checked);
            }}
          />
          <Typography variant="body1">Share with athletes</Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "8px" }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={form.handleSubmit(data => {
              handleSave(data);
            })}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
      {discardChangesOpen && (
        <DiscardChangesDialog
          open={discardChangesOpen}
          onClose={() => {
            setDiscardChangesOpen(false);
          }}
          onDiscard={handleDiscardChanges}
        />
      )}
    </Dialog>
  );
};
