import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, InputAdornment, TextField } from "@mui/material";
import React from "react";
import LabeledSelectNoId from "../../components/universal/LabeledSelectNoId";
import { NamedTagForSelectionFragment, PositionFragment, TeamPreviewFragment } from "../../types";
import { PositionsMultiSelect } from "../Menus/SelectComponents/PositionMultiSelect";
import NamedTagSelector from "../Tags/Selectors/NamedTagSelector";
import TeamSelector from "../Tags/Selectors/TeamSelector";

export const TEAM = "";

export type MenuAttendanceSortType = typeof TEAM;

interface MealMenuAttendanceTableToolbarProps {
  selectedTeams: readonly TeamPreviewFragment[];
  onChangeTeams: (selectedTeams: readonly TeamPreviewFragment[]) => void;
  checkedIn: boolean | null;
  onChangeCheckedIn: (checkedIn: boolean | null) => void;
  hasAccess: boolean | null;
  onChangeHasAccess: (hasAccess: boolean | null) => void;
  searchText: string;
  onChangeSearchText: (searchText: string) => void;
  selectNamedTags: NamedTagForSelectionFragment[];
  onChangeNamedTags: (newTags: NamedTagForSelectionFragment[]) => void;
  positions: PositionFragment[];
  selectedPositions: readonly PositionFragment[];
  onChangePosition: (selectedPositions: readonly PositionFragment[]) => void;
  exportData: () => void;
  isLive: boolean;
  refetchAttendees: () => void;
}

export const MealMenuAttendanceTableToolbar = ({
  selectedTeams,
  onChangeTeams,
  checkedIn,
  onChangeCheckedIn,
  hasAccess,
  onChangeHasAccess,
  searchText,
  onChangeSearchText,
  selectNamedTags,
  onChangeNamedTags,
  positions,
  selectedPositions,
  onChangePosition,
  exportData,
  isLive,
  refetchAttendees,
}: MealMenuAttendanceTableToolbarProps) => {
  const selectedCheckedInOption = checkedIn === true ? "attended" : checkedIn === false ? "not-attended" : "all";
  const selectedHasAccessOption = hasAccess === true ? "has-access" : hasAccess === false ? "no-access" : "all";

  const handleCheckinOnChange = (option: string) => {
    if (option === "attended") {
      onChangeCheckedIn(true);
    } else if (option === "not-attended") {
      onChangeCheckedIn(false);
    } else {
      onChangeCheckedIn(null);
    }
  };

  const handleHasAccessOnChange = (option: string) => {
    if (option === "has-access") {
      onChangeHasAccess(true);
    } else if (option === "no-access") {
      onChangeHasAccess(false);
    } else {
      onChangeHasAccess(null);
    }
  };

  return (
    <Box sx={{ width: "100%", display: "flex", gap: 2, alignItems: "center", justifyContent: "flex-start" }}>
      <TeamSelector
        sx={{ width: "300px" }}
        selectedTeams={selectedTeams}
        onChange={onChangeTeams}
        inputPlaceholder="Teams"
        textInputLabel="Teams"
      />
      <Box sx={{ minWidth: "300px" }}>
        <NamedTagSelector
          selectedNamedTags={selectNamedTags}
          onChange={newNamedTags => onChangeNamedTags(newNamedTags)}
          limitTags={1}
          textInputLabel={"Tags"}
        />
      </Box>
      <PositionsMultiSelect
        allPositions={positions}
        selectedPositions={selectedPositions}
        onChange={onChangePosition}
        fullWidth={true}
        displayedPositionsCount={1}
      />
      <LabeledSelectNoId<string>
        label="Attendance Status"
        placeholder=""
        optionToName={o => (o === "attended" ? "Checked In" : o === "not-attended" ? `Not Checked In` : "View All")}
        selectedOption={selectedCheckedInOption}
        options={["all", "attended", "not-attended"]}
        onChange={handleCheckinOnChange}
      />
      <LabeledSelectNoId<string>
        label="Access"
        placeholder=""
        optionToName={o => (o === "has-access" ? "Has Access" : o === "no-access" ? `No Access` : "View All")}
        selectedOption={selectedHasAccessOption}
        options={["all", "has-access", "no-access"]}
        onChange={handleHasAccessOnChange}
      />
      <TextField
        placeholder={"Search"}
        fullWidth={true}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={e => onChangeSearchText(e.target.value)}
        value={searchText}
      />
      <Button sx={{ width: 350, marginTop: 3 }} onClick={isLive ? refetchAttendees : exportData}>
        {isLive ? "Update" : "Export"}
      </Button>
    </Box>
  );
};
