import React from "react";
import { Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

interface DisplaySettingsTableProps {
  enableEDSA: boolean;
  onChangeEDSASetting: (toggle: boolean) => void;
}

export const EDSASettings = ({ enableEDSA, onChangeEDSASetting }: DisplaySettingsTableProps) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Switch checked={enableEDSA} onChange={() => onChangeEDSASetting(!enableEDSA)} />
            </TableCell>
            <TableCell>
              <Typography variant="h4">Enable Eating Disorders Screen for Athletes (EDSA)</Typography>
              <Typography>
                Athletes will be required to fill out a questionnaire. A score of 3 or higher will automatically disable the athlete’s
                calories and macros for both daily and meal views. This can be overridden later by a dietitian. Learn more about the EDSA{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://pmc.ncbi.nlm.nih.gov/articles/PMC7392177/">
                  here
                </a>
                .
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody></TableBody>
      </Table>
    </TableContainer>
  );
};
