import React from "react";
import { ConfirmationDialog } from "../../../componentLibrary";

interface ArchiveEducationResourceDialogProps {
  open: boolean;
  onCancel: () => void;
  onArchive: () => void;
}

export const ArchiveEducationResourceDialog = ({ open, onCancel, onArchive }: ArchiveEducationResourceDialogProps) => {
  return (
    <ConfirmationDialog
      open={open}
      title="Archive Resource"
      message="Are you sure you want to archive the resource? Doing so will move it to Archived Resources and athletes will no longer be able to access it."
      onCancel={onCancel}
      onConfirm={onArchive}
      confirmLabel="Yes"
      variant="contained"
      messageSx={{ color: "text.primary" }}
    />
  );
};
