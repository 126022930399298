import { isAdvancedSelectionEmpty } from "../Tags/reducers/advancedSelectionReducers";
import { ShareAction, ShareState } from "./types";

export const sharingReducer = (state: ShareState, action: ShareAction): ShareState => {
  switch (action.type) {
    case "ChangeTeamsAction":
      if (state.__typename === "Tags") {
        console.error("You can't assign teams when advancedSelectionState has value");
        return state;
      }
      return { ...state, teams: action.payload.teams };
    case "ClearAdvancedSelectionAction":
      return getEmptyShareState();
    case "ChangeAdvancedSelectionAction":
      // if advanced selection is cleared/empty, reset the view to team selection
      if (isAdvancedSelectionEmpty(action.payload.advancedSelectionState)) {
        return getEmptyShareState();
      }
      return {
        teams: null,
        advancedSelectionState: action.payload.advancedSelectionState,
        __typename: "Tags",
      };
  }
};

export const getEmptyShareState = (): ShareState => ({
  __typename: "Teams",
  teams: [],
  advancedSelectionState: null,
});
