import { datadogRum } from "@datadog/browser-rum";
import { zodResolver } from "@hookform/resolvers/zod";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, Typography } from "@mui/material";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import Loading from "@notemeal/shared/ui/global/Loading";
import { serializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import SomethingWentWrongDialog from "apps/web/src/componentLibrary/ConfirmationDialog/SomethingWentWrongDialog";
import { TWTabGroup } from "apps/web/src/componentLibrary/TWTabGroup/TWTabGroup";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import LoadingButton from "apps/web/src/components/universal/LoadingButton";
import { useBrowserBackAndRefreshWarning } from "apps/web/src/hooks/useBrowserBackAndRefreshWarning";
import { getNavOrgKitchenMenuScheduleImport } from "apps/web/src/pages/Auth/Org/Kitchen/KitchenPaths";
import { trackEvent } from "apps/web/src/reporting/reporting";
import {
  PlannedMenuMealInput,
  useLockPlannedMenuMutation,
  usePlannedMenuLockTakenSubscription,
  useSavePlannedMealsMutation,
} from "apps/web/src/types";
import { useUser } from "apps/web/src/utils/tokens";
import { formatDistanceToNow } from "date-fns";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom-v5-compat";
import { MenuBuilderSchema, MenuBuilderType } from "./Meal/MenuBuilderMealSchema";
import { MenuBuilderExportButton } from "./MenuBuilderExportButton";
import { MenuBuilderLockBanner } from "./MenuBuilderLockBanner";
import { useMenuBuilderContext } from "./MenuBuilderProvider";
import { MenuBuilderWeek } from "./MenuBuilderWeek";
import { UnsavedChangesDialog } from "./UnsavedChangesDialog";

export const MenuBuilderGrid = () => {
  const navigate = useNavigate();
  const currentUser = useUser();
  const { formatYearlessDateWithLocale } = useDateFormatting();

  const {
    menuId,
    menuName,
    weeks,
    loading,
    selectedWeekIndex,
    setSelectedWeekIndex,
    mealsToDelete,
    setMealsToDelete,
    rowsToDelete,
    setRowsToDelete,
    rowItemsToDelete,
    setRowItemsToDelete,
    disabledDays,
    isEditable,
    updatedFormMeals,
    clearUpdatedMeals,
    currentUserHasLock,
    setCurrentUserHasLock,
  } = useMenuBuilderContext();

  const [errorWhileSavingDialog, setErrorWhileSavingDialog] = useState(false);
  const [unsavedChangesDialogOpen, setUnsavedChangesDialogueOpen] = useState(false);
  const [weeksWithErrors, setWeeksWithErrors] = useState<number[]>([]);
  const { setMessage } = useSnackbar();
  const { setBrowserBackAndRefreshWarningEnabled } = useBrowserBackAndRefreshWarning();
  const [errorWhileLockingMenu, setErrorWhileLockingMenu] = useState(false);
  const [lockTakenDetails, setLockTakenDetails] = useState<{ lockCreatedByName: string; lockCreatedAgo: string } | null>(null);

  const [lockPlannedMenu] = useLockPlannedMenuMutation({
    onError: e => {
      console.error(e);
      setErrorWhileLockingMenu(true);
    },
    onCompleted: () => {
      trackEvent("Nutrition | Kitchen | Menu Builder | User Got Edit Access", { menuId });
      setCurrentUserHasLock(true);
    },
  });

  const [savePlannedMeals, { loading: loadingSavePlannedMeals }] = useSavePlannedMealsMutation({
    refetchQueries: ["PlannedMenuGrid"],
    onError: e => {
      setErrorWhileSavingDialog(true);
      console.error(e);
    },
    onCompleted: () => {
      datadogRum.addAction("menu_builder.saved_menu", { menuId });
      setMessage("success", `${menuName} has been saved`);
    },
  });

  usePlannedMenuLockTakenSubscription({
    variables: { plannedMenuId: menuId },
    onSubscriptionData: async data => {
      const plannedMenu = data.subscriptionData.data?.plannedMenuLockTaken.payload.plannedMenu;
      const lockCreatedBy = plannedMenu?.lockCreatedBy;
      if (lockCreatedBy?.id !== currentUser?.id) {
        trackEvent("Nutrition | Kitchen | Menu Builder | User Lost Edit Access", { menuId });
        // undo any changes they had made before losing the lock
        form.reset();
        setLockTakenDetails({
          lockCreatedByName: lockCreatedBy ? `${lockCreatedBy.firstName} ${lockCreatedBy.lastName}` : "unknown",
          lockCreatedAgo: plannedMenu?.lockCreatedAt ? formatDistanceToNow(new Date(plannedMenu?.lockCreatedAt)) : "",
        });
      }
    },
  });

  const form = useForm<MenuBuilderType>({
    resolver: zodResolver(MenuBuilderSchema),
    defaultValues: { weeks: [] },
  });

  const isFormEdited = updatedFormMeals.length > 0 || mealsToDelete.length > 0 || rowsToDelete.length > 0 || rowItemsToDelete.length > 0;

  // lock the menu when the user makes their first edit
  useEffect(() => {
    if (isFormEdited && !currentUserHasLock) {
      lockPlannedMenu({ variables: { input: { plannedMenuId: menuId } } });
    }
  }, [isFormEdited, lockPlannedMenu, menuId, currentUserHasLock]);

  useEffect(() => {
    setBrowserBackAndRefreshWarningEnabled(isFormEdited);
  }, [isFormEdited, setBrowserBackAndRefreshWarningEnabled]);

  if (loading) {
    return (
      <Box sx={{ height: "100%" }}>
        <Loading progressSize="lg" />
      </Box>
    );
  }
  const weekLabels = weeks.map(
    (week, index) => `Week ${index + 1} (${formatYearlessDateWithLocale(week.startDate)} - ${formatYearlessDateWithLocale(week.endDate)})`
  );

  const trackEventReOrder = () => {
    const hasReOrderedRows = form.formState.dirtyFields.weeks?.some((week: any) =>
      week.meals.some((meal: any) => meal.rows.some((row: any) => row.diningStationName === true))
    );

    if (hasReOrderedRows) {
      trackEvent("Nutrition | Web | Kitchen | Menu Builder | Re-order Dining Stations", { menuId });
    }
  };

  // manually handle validation and submission because we want to skip validation on empty meals
  const handleSave = async () => {
    trackEvent("menu_builder.clicked_save", { menuId });
    trackEventReOrder();
    const uniqueMealFormPaths = Array.from(updatedFormMeals);

    if (await form.trigger(uniqueMealFormPaths)) {
      const mealsToUpsert: PlannedMenuMealInput[] = uniqueMealFormPaths.map(formId => {
        const meal = form.getValues(formId);
        return {
          id: meal.id,
          name: meal.mealName,
          type: meal.mealType,
          startTime: meal.startTime,
          endTime: meal.endTime,
          plannedMenuWeekId: meal.weekId,
          themes: meal.themes,
          plannedMenuMealRows: meal.rows.map(row => ({
            id: row.id,
            position: row.position,
            diningStationName: row.diningStationName,
            foodType: row.foodType,
            plannedMenuMealRowItems: row.items.map(item => ({
              id: item.id,
              dayOfWeek: item.dayOfWeek,
              position: item.position,
              menuItemId: item.menuItem.id,
            })),
          })),
        };
      });
      if (mealsToUpsert.length > 0 || mealsToDelete.length > 0 || rowsToDelete.length > 0 || rowItemsToDelete.length > 0) {
        await savePlannedMeals({
          variables: {
            input: {
              plannedMenuId: menuId,
              mealsToDelete,
              rowsToDelete,
              rowItemsToDelete,
              mealsToUpsert,
              disabledDays,
            },
          },
        });
        // set form to not dirty (while keeping edits), but don't unmount everything
        form.reset(form.getValues(), { keepValues: true });
        setMealsToDelete([]);
        setRowsToDelete([]);
        setRowItemsToDelete([]);
        clearUpdatedMeals();
      }
    } else {
      // failed validation, check if there are errors in other week tabs
      const errors = form.formState.errors.weeks;
      if (Array.isArray(errors)) {
        const errorIndices = errors.reduce((indices, hasError, index) => (hasError ? [...indices, index] : null), []);
        datadogRum.addAction("menu_builder.save_failed_validation", { menuId, errors });
        if (errorIndices.length > 1 || errorIndices[0] !== selectedWeekIndex) {
          setWeeksWithErrors(errorIndices);
        }
      }
    }
  };

  const handlePublishClick = () => {
    datadogRum.addAction("menu_builder.table.clicked_publish_menu", { menuId });

    if (isFormEdited) {
      setUnsavedChangesDialogueOpen(true);
    } else {
      navigate(getNavOrgKitchenMenuScheduleImport(serializeDate(weeks[0].startDate), menuId));
    }
  };

  return (
    <Box
      sx={{
        boxSizing: "border-box",
        display: "grid",
        gridTemplateColumns: "auto",
        gridTemplateRows: "auto 1fr",
        overflow: "auto",
      }}
    >
      <MenuBuilderLockBanner />
      <Box sx={{ pb: 3, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <TWTabGroup
          sx={{ maxWidth: 1000 }}
          tabs={weekLabels}
          onSelected={selected => setSelectedWeekIndex(weekLabels.indexOf(selected))} />
        {isEditable && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <MenuBuilderExportButton form={form} weekLabels={weekLabels} />
            <Button
              variant="outlined"
              onClick={handlePublishClick}
              startIcon={<PublishOutlinedIcon />}>
              Publish
            </Button>
            <LoadingButton
              onClick={handleSave}
              buttonText={"Save"}
              loading={loadingSavePlannedMeals}
              icon={<SaveOutlinedIcon />} />
          </Box>
        )}
      </Box>
      <Box sx={{ overflow: "auto" }}>
        {weeks.map((week, index) => {
          return <MenuBuilderWeek
            key={week.id}
            weekFormIndex={index}
            weekId={week.id}
            form={form} />;
        })}
      </Box>

      <ConfirmationDialog
        open={weeksWithErrors.length > 0}
        title="Unable to Save Menu"
        message={
          <Box>
            <Typography>
              The menu cannot be saved due to unresolved issues. Please check and fix the errors on the following weeks in order to proceed:
            </Typography>
            <List sx={{ listStyleType: "disc", pl: 3, pb: 0 }}>
              {weeksWithErrors.map(weekIndex => (
                <ListItem sx={{ display: "list-item", p: 0 }}>{weekLabels[weekIndex]}</ListItem>
              ))}
            </List>
          </Box>
        }
        onCancel={() => setWeeksWithErrors([])}
        onConfirm={() => setWeeksWithErrors([])}
      />

      <UnsavedChangesDialog
        open={unsavedChangesDialogOpen}
        title="Unsaved Changes"
        message={`The menu has unsaved changes. Please make sure to save the menu in order to publish it.`}
        onCancel={() => {
          setUnsavedChangesDialogueOpen(false);
        }}
        onConfirm={() => {
          setUnsavedChangesDialogueOpen(false);
        }}
        messageSx={{ color: theme => theme.palette.common.black }}
      />

      <SomethingWentWrongDialog
        onClose={() => setErrorWhileSavingDialog(false)}
        open={errorWhileSavingDialog}
        loading={loading}
        content={"We were unable to save the menu due to internal server errors. Please try again by clicking on “Save”."}
      />

      <Dialog open={loadingSavePlannedMeals}>
        <DialogTitle>Save Menu</DialogTitle>
        <DialogContent>
          <Loading />
          <Typography sx={{ display: "flex", alignSelf: "center" }} variant="h4">
            Saving in progress...
          </Typography>
          <Typography variant="body2" color="mediumEmphasisText">
            Please sit back and wait momentarily as we finish saving the menu.
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog open={lockTakenDetails !== null}>
        <DialogTitle>Lost Editing Access</DialogTitle>
        <DialogContent>
          <Typography>
            {lockTakenDetails?.lockCreatedByName} has taken over the access to edit the menu {lockTakenDetails?.lockCreatedAgo} ago. Please
            note that you will only be able to view the menu now.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setLockTakenDetails(null);
            }}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>

      <SomethingWentWrongDialog
        onClose={() => setErrorWhileLockingMenu(false)}
        open={errorWhileLockingMenu}
        content={
          "Looks like something went wrong. Please try closing and reopening the menu. If the issue persists, please contact Nutrition support."
        }
      />
    </Box>
  );
};
