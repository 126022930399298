import { logger } from "@notemeal/server/log";
import axios from "axios";

export const useDeleteResource = () => {
  const deleteUrl = `${process.env.REACT_APP_URL_SCHEME}${process.env.REACT_APP_SERVER_DOMAIN}/delete-education-resource`;

  const deleteResource = async (fileKey: string) => {
    try {
      await axios.delete(deleteUrl, {
        params: { fileKey },
        withCredentials: true,
      });
    } catch (err) {
      logger.error({
        type: "misc",
        message: (err as Error).message,
      });
    }
  };
  return deleteResource;
};
