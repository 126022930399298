import { NotemealProfile } from "@notemeal/profile-sync";
import React, { useState } from "react";
import { filterProfileForSearchTerm } from "../../utils";
import ProfilesList from "./ProfilesList";

interface NotemealProfilesListProps {
  selectedId: string | null;
  onLink: (profile: NotemealProfile) => void;
  onCancel: () => void;
  profiles: readonly NotemealProfile[];
}

const NotemealProfilesList = (props: NotemealProfilesListProps) => {
  const { selectedId, profiles } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const searchProps = { searchTerm, setSearchTerm };
  const filteredProfiles = profiles.filter(p => {
    const firstName = p.__typename === "Athlete" ? p.firstName : p.user.firstName;
    const lastName = p.__typename === "Athlete" ? p.lastName : p.user.lastName;
    return filterProfileForSearchTerm({
      firstName,
      lastName,
      searchTerm,
    });
  });

  return (
    <ProfilesList
      {...props}
      searchProps={searchProps}
      profiles={filteredProfiles}
      title="Unlinked Nutrition Profiles"
      columnMode={selectedId ? "NoAction" : "Link"}
      disableOnSelected
      getProfileForDisplay={p =>
        // missingTeamworksRequiredFields not relevant for Nutrition Profile
        p.__typename === "Athlete"
          ? { ...p, missingTeamworksRequiredFields: false, rolesString: "athlete" }
          : { ...p.user, missingTeamworksRequiredFields: false, rolesString: p.roles.join(", ") }
      }
    />
  );
};

export default NotemealProfilesList;
